import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const LeftSideBarStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #1c1454;
  border-top-right-radius: 30px;
  color: #fff;
  width: 80px;
  transition: width 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: 30;
  grid-area: leftsidebar;
  border-right: 1px solid ${(props) => (props.mode ? '#786dc1' : '#000')};

  &:hover {
    width: 219px;
    cursor: pointer;
  }

  &:hover .text {
    display: block;
  }

  &:hover .text-holder {
    display: block;
  }

  &:hover .brand-name .logo-text {
    display: block;
  }

  &:hover .dark-mode-switch {
    flex-direction: row;

    span {
      margin-bottom: 0px;
      margin-right: 12px;
      font-size: 16px;
    }
  }

  .sidebar-content {
    position: relative;
    overflow: hidden;
    top: 4.5vh;
    display: grid;
    height: 100%;
  }

  .brand-name {
    margin: 0 auto;
    margin-bottom: 76px;
    display: flex;
    align-items: center;

    .logo {
      font-size: 10px;
      width: 28px;
      height: 28px;
      margin-right: 5px;
    }

    .logo-text {
      display: none;
      font-size: 22px;
    }
  }

  .sidebar-items {
    list-style: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0;

    .sidebar-item {
      margin: 12px 0;
    }
  }

  .dark-mode-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span {
      margin-right: 0px;
      margin-bottom: 14px;
      font-weight: 600;
      font-size: 12px;
    }
  }
`;

const NavLinkStyled = styled(NavLink)`
  color: #fff;
  padding: 10px 20px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  transition: all 400ms ease-out;

  &.not-active {
    background: red;

    &:not(hover) {
      background: none;
    }
  }

  &:hover {
    background: #a2a2a259;
  }

  .text {
    display: none;
    /* color: #777298; */
  }

  .text-holder {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 20px;
    display: none;

    .not-active-text {
      color: #d4d4d4;
    }

    .small-text {
      font-size: 0.7rem;
      position: relative;
      top: -3px;
      color: #374dd0;
    }
  }

  &:hover {
    background: #a2a2a259;
  }

  .extract {
    color: #f04f45;
  }

  .visualize {
    fill: #777298;
    /* filter: grayscale(); */
    /* color: #06edc2 !important; */
  }

  .clone {
    fill: #ff7b38;
    /* filter: grayscale(); */
  }

  .migrate {
    /* fill: #fbd600; */
    /* filter: grayscale(); */
    fill: #777298;
  }

  &:hover {
    text-decoration: none;
    color: #fff;
  }

  &:focus {
    outline: 0;
  }

  &.is-active {
    background: #2a10e1;
  }

  .text {
    display: none;
  }
`;

export { LeftSideBarStyled, NavLinkStyled };
