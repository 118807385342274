import React from 'react';
import './spinner.css';

export const Spinner = () => {
  return (
    <div class='lds-ring'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
