import React from 'react';
import { useSelector } from 'react-redux';
import { RightSideBarStyled } from './right-sidebar';
import { Link } from 'react-router-dom';
// import { ReactComponent as ProfileIcon } from 'assets/Icons/profile.svg';
import { ReactComponent as DocumentationIcon } from 'assets/Icons/documentation.svg';
import { ReactComponent as SupportIcon } from 'assets/Icons/support.svg';

function RightSideBar({ mode }) {
  const tokenVal = useSelector((state) => state.project.bodyToken);

  return (
    <RightSideBarStyled mode={mode}>
      {/* <ProfileIcon className="profile-icon" /> */}
      <div className="other-options">
        <Link className="support" target="_blank" to={"/community?jwt=" + tokenVal.token}>
          <span>Community</span>
          <DocumentationIcon />
        </Link>
        <a className="support" rel="noopener noreferrer" target="_blank" href="https://alluvium-hq.atlassian.net/wiki/display/AA/Scrum+Companion">
          <span>Documentation</span>
          <DocumentationIcon />
        </a>
        <a className="support" rel="noopener noreferrer" target="_blank" href="https://alluvium-hq.atlassian.net/servicedesk/customer/portal/11">
          <span>Support</span>
          <SupportIcon className="support-icon" />
        </a>
      </div>
    </RightSideBarStyled>
  );
}

export default RightSideBar;
