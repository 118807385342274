import React from 'react';
import { Button } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonWrapper } from './onBoarding.style';

const OnBoardBtn = ({ baseColor, position, text, color, changed, isLoading, idle, visibility }) => {
  const style =
    isLoading || idle
      ? {
          pointerEvents: 'none',
          background: position ? '#5567d6eb' : '#1db99b',
          borderColor: position ? ' #5567d6eb' : '#1db99b',
          visibility: visibility,
        }
      : {};
  return (
    <ButtonWrapper background={baseColor} position={position} color={color}>
      <Button type="primary" shape="round" size="large" style={style} onClick={changed} block={true}>
        {position ? (
          <>
            <CaretLeftOutlined />
            <span>{text}</span>
          </>
        ) : (
          <>
            <span>{text}</span>
            {!isLoading ? <CaretRightOutlined /> : <FontAwesomeIcon icon="spinner" spin size="sm" />}
          </>
        )}
      </Button>
    </ButtonWrapper>
  );
};

export default OnBoardBtn;
