import React, { useState, useEffect } from 'react';
import './SearchBar.css';


const SearchBar = ({ viewSelectedReport,handleClick,data,searchValue, setSearchValue })=>{
    const [ searchView, setSearchView ] = useState(false);
    let searchResults = [];

    const handleSearchInput = (e) =>{
        setSearchValue(e.target.value);
    }

    useEffect(() => {
        if(searchResults.length > 0){
            setSearchView(true);
        }else{
            setSearchView(false);
        }
    }, [searchResults])

    if( searchValue.length > 0 ){
        
        searchResults = data.filter((i)=>{
            let searchMatch = []
            try {
                searchMatch=i.topic.toLowerCase().match(searchValue);
            } catch (error) {
                console.log(error)
            }
           return searchMatch;
        })
    }
    

    return(
        <div className={`search-form ${viewSelectedReport === false ? 'show-search': ''}`}>
            <div className={`search-input ${searchView ? "enable-search-view" : "disable-search-view"}`}>
                <input 
                    type="text" 
                    required 
                    placeholder="Search report" 
                    value={ searchValue } 
                    onChange={ handleSearchInput }    
                />
                <div className="search-btn">
                    <img src={ require('../../assets/Icons/search.svg')}  alt="search"/>
                </div>
            </div>
            <ul className="search-results">
                {
                    searchResults.map((report,idx)=>{
                        return (
                            <li key={ idx } onClick={ ()=>handleClick(report.id) }>{report.topic}</li>
                        )
                    })
                    
                }
            </ul>
        </div>
    )
}

export default SearchBar;