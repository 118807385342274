export function getProjectFromStorage() {
  try {
    return JSON.parse(localStorage.getItem('single_project')) || {};
  } catch (err) {
    console.log(err);
  }
}

export function saveProjectToStorage(val) {
  try {
    return localStorage.setItem('single_project', JSON.stringify({ ...val }));
  } catch (err) {
    console.log(err);
  }
}
