import React, { useState } from "react";
import Header from "components/Header";
import LeftSideBar from "components/SideBar/LeftSideBar";
import RightSideBar from "components/SideBar/RightSideBar";
import { ContentStyled } from "./content.style";
import { DashboardStyled } from "./dashboard.style";
import MainHeader from "containers/MainHeader";
import MainContent from "containers/MainContent";
import OnBoarding from "containers/onBoarding";
import SideBarViewType from "components/SelectedProject";

function Dashboard({ projectsLoading, handleToggle, toggle }) {
    const [collapsible, setCollapsible] = useState(false);
    const [expandedView, setExpandedView] = useState(false);

    function handleCollapsible() {
        setCollapsible((prevState) => !prevState);
    }

    function handleExpandedView() {
        setExpandedView((prevState) => !prevState);
    }

    return (
        <>
            <DashboardStyled>
                <LeftSideBar onToggle={handleToggle} mode={toggle} />
                <Header />
                <ContentStyled
                    className={` ${!collapsible && " collapse"}`}
                    expandedView={expandedView}
                >
                    <SideBarViewType
                        mode={toggle}
                        collapsible={collapsible}
                        handleCollapsible={handleCollapsible}
                        loading={projectsLoading}
                    />
                    <MainHeader
                        mode={toggle}
                        collapsible={collapsible}
                        handleCollapsible={handleCollapsible}
                    />
                    <MainContent
                        mode={toggle}
                        expandedView={expandedView}
                        handleExpandedView={handleExpandedView}
                    />
                    <OnBoarding />
                </ContentStyled>
                <RightSideBar mode={toggle} />
            </DashboardStyled>
        </>
    );
}

export default Dashboard;
