import useVisualize from "helpers/custom-hooks/visualize";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import localForage from "localforage";
import moment from "moment";

export default function ReleaseVisualize() {
    const current = useSelector((state) => state.project.currentRelease) || "";

    const { visualizeReleaseData, getVisualizeReleaseData } = useVisualize();

    console.log(visualizeReleaseData)

    const tableData_counter =
        visualizeReleaseData &&
        visualizeReleaseData.data &&
        Object.entries(visualizeReleaseData && visualizeReleaseData?.counter_data).map(
            (el) => {
                console.log(el)
                return {
                    title: el[0],
                    count: el[1],
                    // inProgress: moment(el[1]["In Progress"]).format("DD/MM/YYYY"),
                };
            }
        )
    ;

    const tableData_main =
        visualizeReleaseData &&
        visualizeReleaseData.data &&
        Object.entries(visualizeReleaseData && visualizeReleaseData?.data).map(
            (el) => {
                console.log(el)
                return {
                    title: el[0],
                    properties: el[1],
                    // inProgress: moment(el[1]["In Progress"]).format("DD/MM/YYYY"),
                };
            }
        )
    ;
    

    const timelineData =
        visualizeReleaseData &&
        visualizeReleaseData.data &&
        Object.entries(visualizeReleaseData && visualizeReleaseData?.timeline_data).map(
            (el) => {
                    return {
                        title: el[0],
                        created: el[1].Created,
                        todo: el[1]["To Do"],
                        inProgress: el[1]["In Progress"] ? moment(el[1]["In Progress"]).format("DD/MM/YYYY") : "-" ,
                    };
            }
        )
    ;

    useEffect(() => {
        localForage.getItem("single_project_credentials").then((res) => {
            getVisualizeReleaseData({ ...res, release_name: current.name });
        });
    }, [current]);

    var config = {
        appendPadding: 30,
        data: timelineData,
        xField: 'xG conceded',
        yField: 'Shot conceded',
        colorField: 'Result',
        color: ['#c71e1d', '#ffca76', '#09bb9f'],
        size: 5,
        shape: 'circle',
        pointStyle: { fillOpacity: 1 },
        yAxis: {
        nice: true,
        line: { style: { stroke: '#aaa' } },
        },
        xAxis: {
        grid: { line: { style: { stroke: '#eee' } } },
        line: { style: { stroke: '#aaa' } },
        },
        label: {},
    };

    console.log(
        "dashboardReleaseData=====",
       visualizeReleaseData,
       timelineData
    );
    console.log(timelineData)

    return (
        <div>
        {
            visualizeReleaseData 
            ?
            <div className="visualize-tables">
                <div className="visualize-table-counter_data">
                    <table>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                        {   
                            tableData_counter 
                                .sort(
                                    (a, b) =>
                                        b.title - a.title
                                )
                                .map(
                                    (el, index) => {
                                        console.log(el)
                                        return (
                                            <tr key={ index }>
                                                <td>
                                                    {el.title}
                                                </td>
                                                <td>
                                                    {el.count}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                        </tbody>
                    </table>
                </div>
                <div className="visualize-table-main_data">
                    <table>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Properties</th>
                            </tr>
                        </thead>
                        <tbody>
                        {   
                            tableData_main
                                .map(
                                    (el, index) => {
                                        console.log(el)
                                        return (
                                            <tr key={ index }>
                                                <td>
                                                    <th>{ el.title }</th>
                                                    <tr>
                                                        { Object.keys(el.properties).map(
                                                            (property_title)=>{
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            {property_title}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        ) }
                                                    </tr>
                                                </td>
                                                <td>
                                                    <th>Count</th>
                                                    <tr>
                                                        { Object.values(el.properties).map(
                                                        (property_value)=>{
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {property_value}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        ) }
                                                    </tr>
                                                    
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                </div>
                <div className="timeline-table">
                    <h3>Story points</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Created</th>
                                <th>To Do</th>
                                <th>In Progress</th>
                            </tr>
                        </thead>
                        <tbody>
                        {   
                            timelineData
                                .sort(
                                    (a, b) =>
                                        b.title - a.title
                                )
                                .map(
                                    (el, index) => {
                                        console.log(el)
                                        return (
                                            <tr key={ index }>
                                                <td>
                                                    {
                                                        
                                                        el.title
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        moment(el.created).format("DD/MM/YYYY")
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        moment(el.todo).format("DD/MM/YYYY")
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        el.inProgress
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                        </tbody>
                    </table>
                </div>
            </div>
            :
                <div className="not-found"> No data available </div>
        }
        </div>
    );
}
