import useMigrate from 'helpers/custom-hooks/migrate';
import React from 'react';

export default function MigrateSuccess() {
    const {migrateSuccessData} = useMigrate();

    return (
        <div className="operation-body">
            <div className="migrate-report">
                <div className="visualise-data-container">
                    <div className="visualize-data">
                        Success
                    </div>
                    {/* <div>{loading ? <Spinner /> : handleChart()}</div> */}
                </div>
            </div>
        </div>
    );
}
