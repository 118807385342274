/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';

/**
 *@description A class that shows up when the app crashes
 *
 * @class ErrorBoundary
 * @extends {Component}
 */
class ErrorBoundary extends Component {
  /**
   *Creates an instance of ErrorBoundary.
   * @param {*} props
   * @memberof ErrorBoundary
   */
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  /**
   *
   *
   * @static
   * @param {*} error
   * @returns
   * @memberof ErrorBoundary
   */
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  /**
   *
   *
   * @param {*} error
   * @param {*} info
   * @memberof ErrorBoundary
   */

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  /**
   *
   *
   * @returns
   * @memberof ErrorBoundary
   */
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error-boundary">
          <div className="error-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <h1>An Unknown error occured!</h1>
            <h4>Kindly try again later</h4>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
