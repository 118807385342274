import React, { useEffect } from "react";
import useFilters from "helpers/custom-hooks/filters";
import { useSelector } from "react-redux";
import localForage from "localforage";

export default function FiltersVisualize() {
    const current = useSelector((state) => state.project.currentFilter) || "";

    const { filterData, getFiltersData } = useFilters();

    const tableData_counter =
        filterData &&
        filterData.data &&
        Object.entries(filterData && filterData?.counter_data).map(
            (el) => {
                console.log(el)
                return {
                    title: el[0],
                    count: el[1],
                    // inProgress: moment(el[1]["In Progress"]).format("DD/MM/YYYY"),
                };
            }
        );

    const tableData_main =
        filterData &&
        filterData.data &&
        Object.entries(filterData && filterData?.data).map(
            (el) => {
                console.log(el)
                return {
                    title: el[0],
                    properties: el[1],
                    // inProgress: moment(el[1]["In Progress"]).format("DD/MM/YYYY"),
                };
            }
        );

        useEffect(() => {
            localForage.getItem("single_project_credentials").then((res) => {
                getFiltersData({ ...res, filter_name: current.name });
            });
    }, [current]);

    console.log(tableData_main)


    return (
        <div>
            {/* <Chart data={tableData} /> */}
            {
                filterData 
                ?
                <div className="visualize-tables">
                    <div className="visualize-table-counter_data">
                        <table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                            {   
                                tableData_counter 
                                    .sort(
                                        (a, b) =>
                                            b.title - a.title
                                    )
                                    .map(
                                        (el, index) => {
                                            console.log(el)
                                            return (
                                                <tr key={ index }>
                                                    <td>
                                                        {el.title}
                                                    </td>
                                                    <td>
                                                        {el.count}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                            </tbody>
                        </table>
                    </div>
                <div className="visualize-table-main_data">
                    <table>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Properties</th>
                            </tr>
                        </thead>
                        <tbody>
                        {   
                            tableData_main
                                .map(
                                    (el, index) => {
                                        console.log(el)
                                        return (
                                            <tr key={ index }>
                                                <td>
                                                    <th>{ el.title }</th>
                                                    <tr>
                                                        { Object.keys(el.properties).map(
                                                            (property_title)=>{
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            {property_title}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        ) }
                                                    </tr>
                                                </td>
                                                <td>
                                                    <th>Count</th>
                                                    <tr>
                                                        { Object.values(el.properties).map(
                                                        (property_value)=>{
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {property_value}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        ) }
                                                    </tr>
                                                    
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className="not-found"> No data available </div>
            }
        </div>
    );
}
