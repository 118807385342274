import React from 'react';
import RadioInput from '../checkbox';
import { RadioGroup } from './OnBoardRadioGroup.style';

const OnBoardRadioGroup = (props) => (
  <RadioGroup className="radioGroup-onboard">
    {props.projects.map((project, index) => (
      <RadioInput project={project} key={project.key} index={index} projectName={project.name} projectKey={project.key} isOnBoarding={true} config={{ name: 'radio' }} />
    ))}
  </RadioGroup>
);

export default OnBoardRadioGroup;
