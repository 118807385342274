import React from 'react';
import { HeaderStyled } from './header.style';
// import { ReactComponent as BellIcon } from 'assets/Icons/bell.svg';
// import { ReactComponent as PlusIcon } from 'assets/Icons/newplus.svg';

function Header() {
  return (
    <HeaderStyled>
      <div className="header">
        <div className="title">SCRUM COMPANION</div>
        <div className="other-options">
          <div className="option">
            {/* <BellIcon className="bell-icon" /> */}
            {/* <span className="option-text">Notification</span> */}
            {/* <span className="notifcation-count">3</span> */}
          </div>
          <div className="option">
            {/* <PlusIcon className="plus-icon" /> */}
            {/* <span className="option-text">New Instance</span> */}
          </div>
        </div>
      </div>
    </HeaderStyled>
  );
}

export default Header;
