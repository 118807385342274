import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import "../Landing Page/style.css";

class LandingPage extends React.Component{
    constructor(props){
        super(props)
        this.state={ 
            featureOneOpen:false,
            featureTwoOpen:false,
            featureThreeOpen:false 
        }

        this.openFeatureOne = this.openFeatureOne.bind(this);
        this.openFeatureTwo = this.openFeatureTwo.bind(this);
        this.openFeatureThree = this.openFeatureThree.bind(this);
    }

    componentDidMount(){
        AOS.init();
    }

    openFeatureOne(){
        this.setState(state=>({
            featureOneOpen: !state.featureOneOpen
        }));
    }

    openFeatureTwo(){
        this.setState(state=>({
            featureTwoOpen: !state.featureTwoOpen
        }));
    }

    openFeatureThree(){
        this.setState(state=>({
            featureThreeOpen: !state.featureThreeOpen
        }));
    }

    render(){
        return(
            <div className="landing-page">
                <header className="jumbotron">
                    <nav className="nav-bar">
                        <ul>
                            <li className="nav-item">
                                <Link to="/"><img src={require("../../assets/Logo/sproj logo .png")} alt="Sproj logo"/></Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/" className="btn get-started-btn">Get Started</Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="sign-up">
                        <div className="sign-up-info">
                            <p className="title" data-aos="fade-down" data-aos-duration="1000">Scrum Companion software</p>
                            <h1 className="main-title" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">Elevate team independence and ownership</h1>
                            <p className="body" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200">
                                A collaborative platform allowing teams to  seamlessly 
                                view and manage raw data all in one click.
                            </p>
                            <Link className="btn sign-up-btn" data-aos="fade-right">
                                Sign up Free
                            </Link>
                        </div>
                        <div className="illustration" data-aos="fade-left">
                            <img src={require("../../assets/Homepage Assets/illustrations/Group 156.svg")} alt="jumbotron illustration"/>
                        </div>
                        
                    </div>
                    <div className="testimony-companies-container">
                        <p className="title">Scrum Companion's principles have worked for</p>
                        <div className="testimony-companies" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1500">
                            <img src={require("../../assets/Homepage Assets/companies/Group 17.svg")} alt="company"/>
                            <img src={require("../../assets/Homepage Assets/companies/Fill 32.svg")} alt="company"/>
                            <img src={require("../../assets/Homepage Assets/companies/Group 19.svg")} alt="company"/>
                            <img src={require("../../assets/Homepage Assets/companies/Group 20.svg")} alt="company"/>
                            <img src={require("../../assets/Homepage Assets/companies/Group 22.svg")} alt="company"/>
                            <img src={require("../../assets/Homepage Assets/companies/Group 23.svg")} alt="company"/>
                            <img src={require("../../assets/Homepage Assets/companies/Fill 251.svg")} alt="company"/>
                            <img src={require("../../assets/Homepage Assets/companies/Group 37.svg")} alt="company"/>
                            <img src={require("../../assets/Homepage Assets/companies/Group 35.svg")} alt="company"/>
                            <img src={require("../../assets/Homepage Assets/companies/Group 34.svg")} alt="company"/>
                            <img src={require("../../assets/Homepage Assets/companies/Group 32.svg")} alt="company"/>
                            <img src={require("../../assets/Homepage Assets/companies/Group 28.svg")} alt="company"/>
                            <img src={require("../../assets/Homepage Assets/companies/Group 26.svg")} alt="company"/>
                            <img src={require("../../assets/Homepage Assets/companies/Group 25.svg")} alt="company"/>
                        </div>
                    </div>
                    <div className="header-background">
                        <img src={require("../../assets/Homepage Assets/header/header background .svg")} alt="header" />
                    </div>
                    
                </header>
                <section className="features-section">
                    <p className="features-section-title">Get unrivalled results in 60 seconds</p>
                    <div className="features">
                        <div className="feature visualise-feature" data-aos="fade-down" data-aos-duration="900">
                            <div className="feature-description">
                                <p className="feature-title">Visualise your workflow</p>
                                <p className="feature-details">
                                    Bring projects to life anytime, anywhere, with offline data 
                                    availability for uninterrupted continuous workflow. 
                                </p>
                            </div>
                            <div className="feature-icon">
                                <img src={require("../../assets/Homepage Assets/icons/Path.svg")} alt="visualise"/>
                            </div>
                            <div className="feature-img">
                                <img src={require("../../assets/Homepage Assets/illustrations/Group 52.svg")} alt="feature"/>
                            </div>
                            
                        </div>
                        <div className="feature extract-feature" data-aos="fade-down" data-aos-duration="900" data-aos-delay="100">
                            <div className="feature-description">
                                <p className="feature-title">Extract and export projects to CSV format</p>
                                <p className="feature-details">
                                    Extract data from any Jira project to CSV, allowing 
                                  external access to data from team projects outside Jira.
                                </p>
                                
                            </div>
                            <div className="feature-icon">
                                <img src={require("../../assets/Homepage Assets/icons/Group 3.svg")} alt="visualise"/>
                            </div>
                            <div className="feature-img">
                                <img src={require("../../assets/Homepage Assets/illustrations/Group 62.svg")} alt="feature"/>
                            </div>
                        </div>
                        <div className="feature clone-feature" data-aos="fade-down" data-aos-duration="900" data-aos-delay="200">
                            <div className="feature-description">
                                <p className="feature-title">Clone data for projects</p>
                                <p className="feature-details">
                                    Effortless clone data from any team project 
                                    all in one SingleProject with ease and no complication. 
                                </p>
                                
                            </div>
                            <div className="feature-icon">
                                <img src={require("../../assets/Homepage Assets/icons/Group.svg")} alt="visualise"/>
                            </div>
                            <div className="feature-img">
                                <img src={require("../../assets/Homepage Assets/illustrations/Group 61.svg")} alt="feature"/>
                            </div>
                        </div>
                        <div className="feature migrate-feature" data-aos="fade-down" data-aos-duration="900" data-aos-delay="300">
                            <div className="feature-description">
                                <p className="feature-title">Migrate Project in Jira </p>
                                <p className="feature-details">
                                    Move data seamlessly from one team project to the 
                                    other with simple migration of any Jira data. 
                                </p>
                               
                            </div>
                            <div className="feature-icon">
                                <img src={require("../../assets/Homepage Assets/icons/Group 3 Copy 3.svg")} alt="visualise"/>
                            </div>
                            <div className="feature-img">
                                <img src={require("../../assets/Homepage Assets/illustrations/Group 65.svg")} alt="feature"/>
                            </div>
                        </div>
                    </div>
                    <Link to="/" className="btn get-started-btn">Get Started</Link>
                </section>
                <section className="more-features-section">
                    <div className="more-features">
                        <div className="feature manage-feature" data-aos="fade-down" data-aos-duration="1000" >
                            <div className={`feature-header ${this.state.featureOneOpen?"clicked":""}`} onClick={this.openFeatureOne}>
                                <img src={require("../../assets/Homepage Assets/icons/target.svg")} alt="feature" className="feature-icon"/>
                                <h3>Cross Project Release Management</h3>
                                <img src={require("../../assets/Homepage Assets/icons/arrow.svg")} alt="arrow" className="open-arrow"/>
                            </div>
                            
                            <p className={`feature-body ${this.state.featureOneOpen?"open-feature-body":""}`}>
                                Release management, visualize release at once, release extract
                            </p>
                        </div>
                        <div className="feature collaborative-feature" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">
                            <div className={`feature-header ${this.state.featureTwoOpen?"clicked":""}`} onClick={this.openFeatureTwo} >
                                <img src={require("../../assets/Homepage Assets/icons/Group 2.svg")}  alt="feature" className="feature-icon"/>
                                <h3>Project Data Management</h3>
                                <img src={require("../../assets/Homepage Assets/icons/arrow.svg")}  alt="arrow" className="open-arrow"/>
                            </div>
                            <p className={`feature-body ${this.state.featureTwoOpen?"open-feature-body":""}`}>
                                Extract, Visualize, Clone the project, Migrate
                            </p>
                        </div>
                        <div className="feature productivity-feature" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200">
                            <div className={`feature-header ${this.state.featureThreeOpen?"clicked":""}`} onClick={this.openFeatureThree}>
                                <img src={require("../../assets/Homepage Assets/icons/Vector 1.svg")}  alt="feature" className="feature-icon"/>
                                <h3>Filter Data Management</h3>
                                <img src={require("../../assets/Homepage Assets/icons/arrow.svg")}  alt="arrow" className="open-arrow"/>
                            </div>
                            <p className={`feature-body ${this.state.featureThreeOpen?"open-feature-body":""}`}>
                                Unlimited extract (greater than 1000 issues,  comments, issue hierachy, attachments), visualize status summary, database summary table.
                            </p>
                        </div>
                    </div>
                    <div className="more-features-img">
                        <img src={require("../../assets/Homepage Assets/why sproj section/Group.svg")} alt="Feature"/>
                    </div>
                </section>
                <section className="pricing-and-contact">
                    
                    <div className="pricing">
                        <div className="pricing-header">
                            <img src={require("../../assets/Homepage Assets/icons/Group 12.svg")} alt="pricing-icon"/>
                            <h3>Pricing</h3>
                        </div>
                        <div className="prices" >
                            <div className="price">
                                <h2 className="price-tag">$15</h2>
                                <div className="price-details">
                                    <h5 className="users">Up to 10 users</h5>
                                    <p className="duration">monthly flat feature<br/>
                                        <span className="trial-duration">Free 30 day trial</span>
                                    </p>
                                </div>
                            </div>
                            <div className="price">
                                <h2 className="price-tag">$1.5</h2>
                                <div className="price-details">
                                    <h5 className="users">11 - 100 users</h5>
                                    <p className="duration">monthly flat feature<br/>
                                        <span className="trial-duration">Free 30 day trial</span>
                                    </p>
                                </div>
                            </div>
                            <div className="pricing-examples">
                                <p>101+ users? <Link to="">View pricing examples on Jira </Link></p>
                            </div>
                        </div>
                        
                    </div>
                    <div className="contact">
                        <div className="contact-header">
                            <img src={require("../../assets/Homepage Assets/icons/Group 11.svg")}  alt="contact-icon"/>
                            <h3>Contact us</h3>
                        </div>
                        <div className="contact-info">
                            <div className="contact-details">
                                <h2 className="contact-title">Any Queries</h2>
                                <p className="contact-body">
                                    Our dedicated team of experts are available for any 
                                    support regarding our product. Please get in touch for any enquries.
                                </p>   
                                <h3 className="mobile-number">+44 (20) 3492 4679</h3>
                            </div>
                            <div className="email-contact">
                                <p>Prefer email? <Link to="" >Click here to write to us</Link></p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="get-started-section">
                    <div className="get-started" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1500">
                        <h3 className="get-started-title">Transfrom your workflow with Scrum Companion today</h3>
                        <p className="get-started-subtitle">start for free and upgrade anytime</p>
                        <Link to="/" className="btn get-started-btn">Get started</Link>
                            <img src={require("../../assets/Homepage Assets/pull out section/particle 1.svg")} className="particle-1" alt="particle"/>
                            <img src={require("../../assets/Homepage Assets/pull out section/particle 2.svg")} className="particle-2" alt="particle"/>
                            <img src={require("../../assets/Homepage Assets/pull out section/particle 3.svg")} className="particle-3" alt="particle"/>
                            <img src={require("../../assets/Homepage Assets/pull out section/particle 4.svg")} className="particle-4" alt="particle"/>
                            <img src={require("../../assets/Homepage Assets/pull out section/particle 5.svg")} className="particle-5" alt="particle"/>
                            <img src={require("../../assets/Homepage Assets/pull out section/particle 6.svg")} className="particle-6" alt="particle"/>
                            <img src={require("../../assets/Homepage Assets/pull out section/particle 7.svg")} className="particle-7" alt="particle"/>
                            <img src={require("../../assets/Homepage Assets/pull out section/particle 8.svg")} className="particle-8" alt="particle"/>
                    </div>
                    
                </section>
                <footer>
                    <div className="footer-components">
                        <div className="footer-main">
                            <div className="footer-logo">
                                <img src={require("../../assets/Logo/sproj logo .png")} alt="sproj logo"/>
                            </div>
                            <div className="footer-list">
                                <h5 className="footer-list-header">company</h5>
                                <ul>
                                    <li className="footer-item"><Link>About Scrum Companion</Link></li>
                                    <li className="footer-item"><Link>About Geneisys</Link></li>
                                    <li className="footer-item"><Link>Find us on Jira</Link></li>
                                    <li className="footer-item"><Link>Contact Us</Link></li>
                                </ul>
                            </div>
                            <div className="footer-list">
                                <h5 className="footer-list-header">support</h5>
                                <ul>
                                    <li className="footer-item"><Link>Contact Us</Link></li>
                                    <li className="footer-item"><Link>Technical Support</Link></li>
                                    <li className="footer-item"><Link>FAQ</Link></li>
                                    <li className="footer-item"><Link>Documentation</Link></li>
                                </ul>
                            </div>
                            <div className="footer-list">
                                <h5 className="footer-list-header">socials</h5>
                                <ul>
                                    <li className="footer-item"><a href="">Facebook</a></li>
                                    <li className="footer-item"><a href="">Instagram</a></li>
                                    <li className="footer-item"><a href="">LinkedIn</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="support">
                            <p>support</p>
                            <img src={require("../../assets/Homepage Assets/icons/Group 14.svg")} alt="support"/>
                        </div>
                    </div>
                    <div className="copyright">
                        <p>Copyright Scrum Companion 2020. A product of Geniesys</p>
                        <div className="terms-and-conditions">
                            <Link to="">Terms and Conditions</Link>
                            <Link to="">Privacy Policy</Link>
                        </div>
                    </div>
                </footer>
            </div>
    
        )

    }
    
}
export default LandingPage;