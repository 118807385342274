import * as actionTypes from './actionTypes';
import FileSaver from 'file-saver';
import axios from 'axios';

const fetchStart = () => ({ type: actionTypes.FETCH_CSV_START });
const fetchSuccess = () => ({ type: actionTypes.FETCH_CSV_SUCCESS });
const fetchFail = (message) => ({ type: actionTypes.FETCH_CSV_FAIL, message });
export const clearError = () => ({ type: actionTypes.CSV_CLEAR_ERROR });
export const clearSuccess = () => ({ type: actionTypes.CSV_CLEAR_SUCCESS });
const onToggleDisplay = (actionCreator, options = []) => (dispatch) => {
  setTimeout(() => dispatch(actionCreator(...options)), 7000);
};
const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
export const onFetchCSV = (reqData) => async (dispatch) => {
  try {
    dispatch(fetchStart());
    const { data } = await axios.post('/csv', reqData);
    const blob = b64toBlob(data, 'application/zip');
    dispatch(fetchSuccess());
    dispatch(onToggleDisplay(clearSuccess));
    FileSaver.saveAs(blob, `project__${reqData.project_key}.zip`);
  } catch (err) {
    let message = '';
    const stat = err.response && err.response.status ? err.response.status : 500;
    switch (Number(stat)) {
      case 404:
        message = 'There is no such project with the ID provided';
        break;
      case 401:
        message = 'Your token is Invalid';
        break;
      case 400:
        message = 'your request required a token and a project ID';
        break;
      default:
        message = 'Some error occurred, Please try again';
        break;
    }
    dispatch(fetchFail(message));
    dispatch(onToggleDisplay(clearError));
  }
};

export const cloneSuccess = (payload) => ({
  type: actionTypes.CLONE_SUCCESS,
  payload,
});

export const saveProjects = (payload) => ({
  type: actionTypes.PROJECT_SUCCESS,
  payload,
});

export const isSelectedProject = () => ({
  type: actionTypes.IS_SELECTED,
});

export const setCurrentProject = (payload) => ({
  type: actionTypes.CURRENT_PROJECT,
  payload,
});

export const setCurrentRelease = (payload) => ({
    type: actionTypes.CURRENT_RELEASE,
    payload,
});

export const setCurrentFilter= (payload) => ({
    type: actionTypes.CURRENT_FILTER,
    payload,
});


export const setDashboardData = (payload) => ({
    type: actionTypes.SET_DASHBOARD_DATA,
    payload,
});

export const setDashboardCurrentScreen = (payload) => ({
    type: actionTypes.SET_DASHBOARD_CURRENT_SCREEN,
    payload,
});

export const modalToggleState = (payload) => (dispatch) => {
  dispatch({
    type: actionTypes.TOGGLE_MODAL,
    payload,
  });
};

export const jwtTokenRes = (payload) => ({
  type: actionTypes.ADD_TOKEN_REQ,
  payload,
});

export const sideBarType = (payload) => ({
    type: actionTypes.SIDE_BAR_TYPE,
    payload,
});


export const projectsLoading = (payload) => ({
    type: actionTypes.PROJECTS_LOADING,
    payload,
});


