import styled from 'styled-components';

export const CloseBtnWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 18px;
  .ant-btn {
    background-color: #f04f45;
    color: #ffffff;
    transition: all 0.5s ease-in-out;
    border-color: #f04f45;
  }
  .ant-btn:hover {
    color: #f04f45;
    background: #ffffff;
    border-color: #f04f45;
  }
  .ant-btn span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-btn span svg {
    height: 0.85rem;
  }
`;

export const RadioContainer = styled.div`
  height: 200px;
  overflow-y: auto;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &::-webkit-scrollbar {
    width: 7px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #2b11e1;
    border-radius: 7px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 7px;
  }
`;
export const ModalContent = styled.div`
  min-height: 350px;
  padding: 5px 0;
  margin-top: 35px;

  .search-input {
    width: 100%;
    padding: 5px 10px;
    font-size: 11px;
    outline: 0;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin: 10px auto 20px auto;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
`;
