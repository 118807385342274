import React from 'react';
import { StackedBarChart, LineChart, PieChart } from '@opd/g2plot-react';

const config = {
  // description: {
  //   visible: true,
  //   text: 'dhdhddjddjdjdjdj',
  // },
  padding: 'auto',
  forceFit: true,
  xField: 'name',
  yField: 'value',
  smooth: true,
  xAxis: {
    label: {
      style: {
        fill: 'blue',
      },
    },
    title: {
      style: {
        fill: 'rgba(0, 0, 0, 0.65)',
        stroke: '#ffffff',
      },
    },
    grid: {
      visible: false,
      style: {
        fill: 'rgba(0, 0, 0, 0.65)',
        stroke: '#ffffff',
        lineWidth: 2,
      },
    },
    line: {
      style: {
        color: 'yellow',
        visible: true,
        fill: 'green',
      },
    },
    tickLine: {
      visible: false,
    },
  },
  yAxis: {
    line: {
      visible: true,
      style: {
        color: 'yellow',
        fill: 'green',
        stroke: 'green',
      },
    },
    grid: {
      visible: true,
    },
  },
};

const pieConfig = {
  forceFit: true,
  padding: 'auto',
  angleField: 'value',
  colorField: 'name',
  legend: {
    visible: false,
  },
  label: {
    visible: true,
    type: 'spider',
    offset: 20,
  },
};

const ChartBarLine = ({ height, width, mode, data = [], text = '', type = 'line' }) => {
  let chart = '';
  if (type === 'bar') {
    chart = (
      <StackedBarChart
        color={!mode ? '#2910E1' : '#fff'}
        {...config}
        height={height}
        width={width}
        yAxis={{ label: { style: { fill: mode ? '#fff' : '#000' } } }}
        xAxis={{ label: { style: { fill: mode ? '#fff' : '#000' } } }}
        data={data}
        description={{ visible: true, text }}
        responsive={true}
      />
    );
  } else if (type === 'line') {
    chart = (
      <LineChart
        color={!mode ? '#2910E1' : '#fff'}
        {...config}
        height={height}
        width={width}
        yAxis={{ label: { style: { fill: mode ? '#fff' : '#000' } } }}
        xAxis={{ label: { style: { fill: mode ? '#fff' : '#000' } } }}
        data={data}
        description={{ visible: true, text }}
        responsive={true}
      />
    );
  } else {
    chart = <PieChart data={data} height={height} width={width} color={['#2910E1', '#07EDC2', '#F04F46', '#FF7B38']} description={{ visible: true, text }} responsive={true} {...pieConfig} />;
  }
  return chart;
};

export default ChartBarLine;
