import styled from 'styled-components';

export const TitleWrapper = styled.h3`
font-weight: 600;
font-size: 1.1rem;
letter-spacing: 0.8px;
line-height: 25px;
text-align: center;
color: #374DD0;
margin: ${props => props.top || "100px 0 45px 0 !important"};
`;