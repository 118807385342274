import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import Clone from 'pages/Clone/index';
import Extract from 'pages/Extract';
import { MainContentStyled } from './main-content';
import Visualize from 'pages/Visualize/Index';
import Migrate from "pages/Migrate";
import Landing from "pages/Landing";

const MainContent = ({ collapsible, handleExpandedView, expandedView, mode }) => {
  const location = useLocation();
  const transitions = useTransition(location, (location) => location.pathname, {
    config: { duration: 250 },
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  });

  const isVisualize = location.pathname === '/visualize';

  return (
    <MainContentStyled mode={mode} expandedView={expandedView} className={`${collapsible && 'collapse'}`} isVisualize={isVisualize}>
      {transitions.map(({ item, props, key }) => {
        const style = {
          ...props,
          position: 'absolute',
          width: '100%',
          height: '100%',
        };

        return (
            <animated.div key={key} style={style}>
                <Switch>
                    <Route
                        path={`/`}
                        exact
                        render={() => <Landing mode={mode} />}
                    />
                    <Route
                        path={`/extract`}
                        exact
                        render={() => <Extract mode={mode} />}
                    />
                    <Route
                        path={`/clone`}
                        exact
                        render={(props) => <Clone mode={mode} {...props} />}
                    />
                    <Route
                        path={`/visualize`}
                        exact
                        render={(props) => (
                            <Visualize
                                mode={mode}
                                handleExpandedView={handleExpandedView}
                                expandedView={expandedView}
                                {...props}
                            />
                        )}
                    />
                    <Route
                        path={`/migrate`}
                        exact
                        render={() => <Migrate mode={mode} />}
                    />
                </Switch>
            </animated.div>
        );
      })}
    </MainContentStyled>
  );
};

export default MainContent;
