import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import { CloseBtnWrapper, ModalContent, ModalFooter, RadioContainer } from './onboarding-style';
import OnBoardBtn from '../../components/button/onBoarding';
import PageHeader from '../../components/Title';
import OnBoardInput from '../../components/input/onBoarding';
import OnBoardRadioGroup from '../../components/OnBoardRadioGroup';
import useProjects from 'helpers/custom-hooks/projects';
import { Spinner } from 'components/Spinner/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { SpinnerHolder } from 'pages/Dashboard/dashboard.style';
import { modalToggleState } from 'store/actions/project';
import { Error, ErrorText } from 'components/OnBoardRadioGroup/OnBoardRadioGroup.style';
import { ReactComponent as CancelIcon } from 'assets/images/cancel.svg';

const schema = {
  1: Yup.object().shape({
    domain: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('domain cannot be left blank'),
  }),
  2: Yup.object().shape({
    email: Yup.string().email('invalid email').required('email cannot be left blank'),
  }),
  3: Yup.object().shape({
    api_key: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('api key cannot be left blank'),
  }),
};
const OnBoarding = (props) => {
  const [page, setPage] = useState(1);
  const isSelected = useSelector((state) => state.project.isSelected);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const modalState = useSelector((state) => state.project.modalState);
  const [searchText, setSearchText] = useState('');
  const { getProjects, loading, error, projects } = useProjects();
  const [searchedProject, setSearchProject] = useState(projects);

  const [fields, setFields] = useState({
    domain: {
      value: '',
      isValid: false,
      isChanged: false,
    },
    email: {
      value: '',
      isValid: false,
      isChanged: false,
    },
    api_key: {
      value: '',
      isValid: false,
      isChanged: false,
    },
  });

  const closeModal = () => {
    if (Object.keys(projects).length > 0) {
      dispatch(modalToggleState(false));
    } else {
      enqueueSnackbar('Kindly Fill all required fields to proceed', { variant: 'info' });
    }
  };

  const setPageHandler = (isAdd) => {
    if (isAdd) setPage(page + 1);
    else setPage(page - 1);
  };

  const isIdle = () => {
    switch (page) {
      case 1:
        return !fields.domain.isValid;
      case 2:
        return !fields.email.isValid;
      case 3:
        return !fields.api_key.isValid;
      default:
        return !isSelected || false;
    }
  };

  const handleChange = ({ target }) => {
    const fieldData = { ...fields };
    fieldData[target.name]['value'] = target.value;
    fieldData[target.name]['isChanged'] = Boolean(target.value);
    try {
      fieldData[target.name]['isValid'] = Boolean(schema[page].validateSync({ [target.name]: target.value }));
    } catch (e) {
      fieldData[target.name]['isValid'] = false;
    }
    setFields(fieldData);
  };

  function handleTextChange(e) {
    setSearchText(e.target.value);
  }

  useEffect(() => {
    const results = projects.filter((project) => project.name.toLowerCase().includes(searchText.toLowerCase()));
    setSearchProject(results);
  }, [searchText, projects]);

  const onSubmitHandler = (e) => {
    e.preventDefault();

    // if (page === 1) {
    //   ('hello');
    //   getProjects((prevState) => ({
    //     ...prevState,
    //     domain: fields.domain.value,
    //   }));
    // }

    if (page < 4) {
      if (page === 3) {
        getProjects({
          domain: fields.domain.value,
          email: fields.email.value,
          api_key: fields.api_key.value,
        });
      }
      setPageHandler(true);
    } else {
      closeModal();
    }
  };

  const handle = () => {
    if (loading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Spinner />
        </div>
      );
    } else if (error) {
      return (
        <Error>
          <CancelIcon className="icon" />
          <ErrorText>
            We cannot process this operation at this time. <br /> Kindly try again
          </ErrorText>
        </Error>
      );
    } else {
      return (
        <RadioContainer>
          <OnBoardRadioGroup projects={searchedProject} error={error} />
        </RadioContainer>
      );
    }
  };

  return (
    <>
      {props.loadingModal ? (
        <SpinnerHolder>
          <Spinner />
        </SpinnerHolder>
      ) : (
        <Modal visible={modalState} footer={null} closable={false} style={{ top: '50px' }} width={600}>
          <CloseBtnWrapper>
            <Button shape="circle" icon={<CloseOutlined />} onClick={closeModal} />
          </CloseBtnWrapper>
          <ModalContent>
            <form onSubmit={onSubmitHandler}>
              {
                {
                  1: (
                    <>
                      <PageHeader title="What is your domain ?" />
                      <OnBoardInput
                        config={{
                          placeholder: 'Please enter your domain here',
                          name: 'domain',
                          onChange: handleChange,
                          value: fields.domain.value,
                        }}
                        isChanged={fields.domain.isChanged}
                        isValid={fields.domain.isValid}
                      />
                    </>
                  ),
                  2: (
                    <>
                      <PageHeader title="Provide email address" />
                      <OnBoardInput
                        config={{
                          placeholder: 'Please enter your email here',
                          name: 'email',
                          onChange: handleChange,
                          value: fields.email.value,
                        }}
                        isChanged={fields.email.isChanged}
                        isValid={fields.email.isValid}
                      />
                    </>
                  ),
                  3: (
                    <>
                      <PageHeader title="JIRA API Key" />
                      <OnBoardInput
                        config={{
                          placeholder: 'Please enter your API key',
                          name: 'api_key',
                          type: 'password',
                          onChange: handleChange,
                          value: fields.api_key.value,
                        }}
                        isChanged={fields.api_key.isChanged}
                        isValid={fields.api_key.isValid}
                      />
                    </>
                  ),
                  4: (
                    <>
                      <PageHeader title="Select your project" top="20px !important" />
                      {projects.length > 0 && (
                        <input
                          type="text"
                          onChange={handleTextChange}
                          placeholder="Search by project Name"
                          className="search-input"
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault();
                          }}
                        />
                      )}
                      {handle()}
                    </>
                  ),
                }[page]
              }
            </form>
          </ModalContent>
          <ModalFooter>
            <OnBoardBtn visibility={page === 1 && 'hidden'} text="back" position="left" baseColor="#374DD0" color="#fff" changed={() => setPageHandler()} isLoading={false} idle={page === 1} />
            <OnBoardBtn text={isSelected ? 'finish' : 'next'} baseColor="#0AEDC2" color="#1D1554" changed={onSubmitHandler} isLoading={loading} idle={isIdle()} />
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default OnBoarding;
