import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  width: 165px;
  visibility: ${(props) => props.visibility};

  .ant-btn {
    background: ${(props) => props.background};
    color: ${(props) => props.color};
    border-color: ${(props) => props.background};
    height: 45px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: all ease-in-out 0.4s;

    span[role='img'] {
      display: flex;
      background: transparent;
      position: relative;
      transition: transform ease-in-out 0.4s;
    }
    span[role='img']::after {
      position: absolute;
      height: 2px;
      width: 2rem;
      background: ${(props) => props.color};
      content: '';
      top: 7.5px;
      left: ${(props) => (props.position ? '11px' : '-27px')};
      transition: all ease-in-out 0.4s;
    }
  }
  .ant-btn:hover {
    color: ${(props) => props.background};
    background: ${(props) => props.color};
    span[role='img'] {
      transform: ${(props) => (props.position ? 'translateX(-5px)' : 'translateX(5px)')};
    }
    span[role='img']::after {
      background: ${(props) => props.background};
    }
  }
`;
