import React, { useState } from 'react';
import { OperationStyled } from 'pages/Clone/cone.style';
import { useSelector } from 'react-redux';
import InitilizeMigrate from './InitilizeMigrate';
import EnterCredentials from './EnterCredentials';
import MigrateProject from './MigrateProject';
import MigrateSuccess from './MigrateSuccess';

function Migrate() {
    const currentProject = useSelector((state) => state.project.currentProject);
    const [page, setPage] = useState(1);
    const [migrateData, setMigrateData] = useState({});

    let content = null;

    switch (page) {
        case 1:
            content = <InitilizeMigrate setPage={setPage} />;
            break;
        case 2:
            content = (
                <EnterCredentials
                    page={page}
                    setPage={setPage}
                    setMigrateData={setMigrateData}
                />
            );
            break;
        case 3:
            content = (
                <MigrateProject setPage={setPage} migrateData={migrateData} />
            );
            break;
        case 4:
            content = (
                <MigrateSuccess setPage={setPage} />
            );
            break;
        default:
            break;
    }

    return (
        <OperationStyled>
            <div className="operation-content">
                <div className="operation-header">
                    <span className="title">Migrate</span>
                    <div className="other-info">
                        <div className="project-id">
                            <span className="project-icon" />
                            <span>
                                <span>
                                    {currentProject && currentProject.name}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="operation-body">{content}</div>
            </div>
        </OperationStyled>
    );
}

export default Migrate;
