import styled from 'styled-components';

export const SelectedProjectStyled = styled.div`
  border-radius: 30px;
  background: ${(props) => (props.mode ? '1C1454' : '#fff')};
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  color: #000;
  padding: 15px;
  height: 100%;
  transition: height 300ms ease-out;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  grid-area: selected-project;
  border: 1px solid ${(props) => (props.mode ? '#786dc1' : 'none')};

  .spinner-holder {
    position: fixed;
    top: 0;
    left: 0;
    background: #a39bdc59;
    height: 100vh;
    width: 100vw;
    z-index: 55;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 500ms ease-in;

    .spinner-content {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99999999999999;
      background: #1b1354;
      padding: 20px;
      border-radius: 20px;
      flex-direction: column;
    }

    .spinner-text {
      font-size: 15px;
      text-transform: capitalize;
      margin: 10px 0;
      color: #fff;
    }
  }

  &.collapsible {
    height: 157px;
  }

  .caret {
    margin: auto;
    width: 100%;
    position: absolute;
    bottom: 5px;
    cursor: pointer;
    left: 50%;
    font-weight: 800;
    color: #374cd0;
  }

  .project-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    color: ${(props) => (props.mode ? '#fff' : '#000')};

    .title {
      align-self: center;
      font-weight: 500;
      font-size: 14px;
    }

    .close-holder {
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .close {
      font-size: 20px;
      color: red;
      margin-left: 5px;
    }
  }

  .current-project {
    background: #f5f6fc;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    border-radius: 5px;

    &-icon {
      height: 40px;
      width: 40px;
      background: red;
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #374cd0;

      @media screen and (max-width: 1199px) {
        height: 38px;
        width: 40px;
      }

      &::after {
        width: 50%;
        height: 50%;
        position: absolute;
        border-radius: 50%;
        content: '';
        border: 3px solid #06edc2;
      }
    }

    .project-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;
    }

    .project-name {
      font-size: 13px;
      margin-bottom: 5px;
      letter-spacing: 1.1px;

      @media screen and (max-width: 1199px) {
        font-size: 12px;
        margin-bottom: 5px;
        letter-spacing: 0.1px;
      }
    }

    .project-id {
      color: #babbbf;
      font-size: 12px;
    }
  }

  .projects {
    margin-top: 50px;

    .available-project-title {
      font-weight: 500;
      font-size: 14px;
      color: ${(props) => (props.mode ? '#fff' : '#000')};
    }

    .search-input {
      margin-top: 10px;
      width: 100%;
      padding: 5px 10px;
      font-size: 11px;
      outline: 0;
      border: 1px solid #cccccc;
      border-radius: 5px;
    }

    .radio-holder {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 30px;
      max-height: 48vh;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 7px;
        background-color: #f5f5f5;
        margin-left: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #2b11e1;
        border-radius: 7px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
        border-radius: 7px;
      }

      .radio-container {
        height: fit-content;
        padding: 9px;
        border-radius: 30px;
        margin: 5px 0;
        border: 1px solid ${(props) => (props.mode ? '#fff' : '#2a10e194')};
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 12px;
        transition: all 100ms ease-in-out;
        position: relative;
        width: 44%;
        margin-right: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: keep-all;

        @media (max-width: 1199px) {
          width: 100%;
        }

        .radio-text {
          text-transform: lowercase;
          color: ${(props) => (props.mode ? '#fff' : '#000')};
        }

        &.active-radio {
          background: #2910e1;
          color: white !important;
        }

        input {
          opacity: 0;
          cursor: pointer;
          margin-right: 15px;
        }

        .checkmark {
          position: absolute;
          top: 14px;
          left: 14px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: 1px solid ${(props) => (props.mode ? '#fff' : '#2a10e194')};
        }

        input:checked ~ .checkmark {
          background: #06edc2;
          width: 10px;
          height: 10px;
          border: 0;
        }
      }
    }
  }
`;

export const ProjectAvailabityText = styled.div`
  font-size: 12px;
  margin: auto;
  height: 200px;
  color: #2910e1;
`;
