import * as actionTypes from '../actionTypes';

const initialState = {
    loading: false,
    successMsg: "",
    errMsg: "",
    cloning: false,
    cloneData: "",
    projects: [],
    currentProject: {},
    currentRelease: {},
    currentFilter: {},
    dashboardData: {},
    isSelected: false,
    modalState: false,
    bodyToken: "",
    type: "projects",
    projectsLoading: false,
    currentDashboardScreen: ""
};

const unMutatedStateUpdate = (state, properties) => ({
  ...state,
  ...properties,
});
const fetchStart = (state) => unMutatedStateUpdate(state, { loading: true });
const fetchSuccess = (state) => unMutatedStateUpdate(state, { successMsg: 'Successfully generated CSV', loading: false });
const fetchFail = (state, { message }) => unMutatedStateUpdate(state, { errMsg: message, loading: false });
const clearErr = (state) => unMutatedStateUpdate(state, { errMsg: '' });
const clearSuccess = (state) => unMutatedStateUpdate(state, { successMsg: '' });
const reducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.FETCH_CSV_START:
          return fetchStart(state);
      case actionTypes.FETCH_CSV_SUCCESS:
          return fetchSuccess(state);
      case actionTypes.FETCH_CSV_FAIL:
          return fetchFail(state, action);
      case actionTypes.CSV_CLEAR_SUCCESS:
          return clearSuccess(state);
      case actionTypes.CSV_CLEAR_ERROR:
          return clearErr(state);
      case actionTypes.CLONE_SUCCESS:
          return {
              ...state,
              cloneData: action.payload,
              cloning: false,
          };
      case actionTypes.PROJECT_SUCCESS:
          return {
              ...state,
              projects: [...action.payload],
          };
      case actionTypes.CURRENT_PROJECT:
          return {
              ...state,
              currentProject: action.payload,
          };
      case actionTypes.CURRENT_RELEASE:
          return {
              ...state,
              currentRelease: action.payload,
          };
      case actionTypes.CURRENT_FILTER:
          return {
              ...state,
              currentFilter: action.payload,
          };
      case actionTypes.SET_DASHBOARD_DATA:
          return {
              ...state,
              dashboardData: action.payload,
          };
      case actionTypes.IS_SELECTED:
          return {
              ...state,
              isSelected: true,
          };
      case actionTypes.TOGGLE_MODAL:
          return {
              ...state,
              modalState: action.payload,
          };
      case actionTypes.ADD_TOKEN_REQ:
          return {
              ...state,
              bodyToken: action.payload,
          };
      case actionTypes.SIDE_BAR_TYPE:
          return {
              ...state,
              type: action.payload,
          };
      case actionTypes.PROJECTS_LOADING:
          return {
              ...state,
              projectsLoading: action.payload,
          };
      case actionTypes.SET_DASHBOARD_CURRENT_SCREEN:
          return {
              ...state,
              currentDashboardScreen: action.payload,
          };
      default:
          return state;
  }
};

export default reducer;
