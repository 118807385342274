import styled from 'styled-components';

const ContentStyled = styled.div`
  color: #fff;
  /* background: #f7f8ff; */
  width: 100%;
  height: 84vh;
  grid-area: content;
  display: grid;
  grid-template-columns: 1.2fr 3fr;
  grid-template-rows: 1.1fr 3fr;
  grid-column-gap: 30px;
  grid-template-areas: 'selected-project navbar' 'operation-area  operation-area';

  &.collapse {
    grid-template-areas: 'selected-project navbar' 'selected-project  operation-area';
  }
`;

export { ContentStyled };
