import styled from 'styled-components';

const DashboardStyled = styled.div`
  display: grid;
  /* background: #f7f8ff; */
  grid-template-areas:
    'header header header'
    'leftsidebar content rightsidebar';
  grid-template-columns: 80px auto 90px;
  grid-template-rows: 10vh 70vh;
  grid-gap: 20px;
`;

const SpinnerHolder = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { DashboardStyled, SpinnerHolder };
