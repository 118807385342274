import React from 'react';
// import data from '../../data';
import './view-report.css'

const ReportView = ({ selectedReportNumber,setViewSelectedReport, data }) =>{

    let index = data.findIndex( report => report.id === selectedReportNumber );
    console.log(data[index]);

    const upvote = ()=>{
        let votes = data[index].votes;
        votes = votes+ 1;
    }

    return(
        <main className="view-report-page">
            <div className="back-to-home" onClick={ ()=>setViewSelectedReport(false) }>
                <img src={ require('../../assets/Icons/chevron-left.svg') } alt="chevron left" />
                <h3>Go Back</h3>
            </div>
            <section className="view-report">
                <div className="view-report-details">
                    <div className="attachment">
                        <img src={data[index].image_urls[0] === "" ? require("../../assets/images/test.jpg"): data[index].image_urls[0] } alt="report img"/>
                    </div>
                    <div className="view-report-info">
                        <h1 className="report-title">{data[index].topic}</h1>
                        <h4 className="report-owner">By { data[index].author } </h4>
                        <p className="report-description">
                            {data[index].description}
                        </p>
                        <div className="votes-comments-stats">
                            <div className="report-votes">
                                <img className="votes-icon" src={require("../../assets/Icons/thumbs-up.svg")} alt="votes icon"/>
                                <h4 className="votes-count">{data[index].votes}</h4>
                            </div>
                            <div className="report-comments">
                                <img src={ require('../../assets/Icons/message-circle.svg') } className="comments-icon" alt="comment icon"/>
                                <h4 className="comments-count">{data[index].comments.length}</h4>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="voting">
                    <div className="upvote">
                        <img src={require("../../assets/Icons/upvote.svg")} alt="upvote" onClick={()=>upvote()}/>
                    </div>
                    <div className="downvote">
                        <img src={require("../../assets/Icons/downvote.svg")} alt="downvote"/>
                    </div>

                </div>
            </section>
            <section className="comments-section">
                <div className="comments-section-title">
                    <p>Comments</p>  
                    <span>{data[index].comments.length}</span>
                </div>
                <div className="comments">
                    {
                        data[index].comments.map((comment,idx)=>{
                                
                            return(
                                <div className= "comment" key={idx}>
                                    <p className="comment-details">
                                    { comment.text }
                                    </p>
                                    <h4 className="comment-owner">
                                        { comment.author }
                                    </h4>
                                </div>
                            )
                        })

                            
                            
                    
                    }
                </div>
                <div className="add-comment">
                    <input className="comment-input" type="text"/>
                    <button className="add-comment-btn">
                        Add Comment
                    </button>
                </div>
                
            </section>
        </main>
    )
}

export default ReportView;