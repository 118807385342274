import React from 'react';

function StorageError() {
  return (
    <div className="error-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <h1>An error occured!</h1>
      <h4>Kindly enable LocalStorage</h4>
      <a href="https://voicethread.com/howto/enabling-cookies/">Click here to learn how to enable LocalStorage on your browser</a>
    </div>
  );
}

export default StorageError;
