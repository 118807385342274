export const FETCH_CSV_START = 'FETCH_CSV_START';
export const FETCH_CSV_SUCCESS = 'FETCH_CSV_SUCCESS';
export const FETCH_CSV_FAIL = 'FETCH_CSV_FAIL';
export const CSV_CLEAR_SUCCESS = 'CSV_CLEAR_SUCCESS';
export const CSV_CLEAR_ERROR = 'CSV_CLEAR_ERROR';
export const CLONE_START = 'CLONE_START';
export const CLONE_SUCCESS = 'CLONE_SUCCESS';
export const CLONE_FAILURE = 'CLONE_FAILURE';
export const PROJECT_SUCCESS = 'PROJECT_SUCCESS';
export const CURRENT_PROJECT = 'CURRENT_PROJECT';
export const IS_SELECTED = 'IS_SELECTED';
export const GET_PROJECTS_START = 'GET_PROJECTS_START';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const ADD_TOKEN_REQ = 'ADD_TOKEN_REQ';
export const SIDE_BAR_TYPE = "SIDE_BAR_TYPE";
export const CURRENT_RELEASE = "CURRENT_RELEASE";
export const CURRENT_FILTER = "CURRENT_FILTER";
export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";
export const PROJECTS_LOADING = "PROJECTS_LOADING";
export const SET_DASHBOARD_CURRENT_SCREEN = "SET_DASHBOARD_CURRENT_SCREEN";
