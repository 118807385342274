import styled from 'styled-components';

export const MainContentStyled = styled.div`
  background: ${(props) => (!props.mode ? '#fff' : 'none')};
  height: ${(props) => (props.expandedView && props.isVisualize ? '84vh' : '100%')};
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  grid-area: operation-area;
  width: ${(props) => (props.expandedView && props.isVisualize ? '85.6vw' : '100%')};
  overflow-x: hidden;
  position: ${(props) => (props.expandedView && props.isVisualize ? 'absolute' : 'relative')};
  transition: width 300ms ease-in-out;
  color: ${(props) => (props.mode ? '#fff' : '#000')};
  border: 0.5px solid ${(props) => (props.mode ? '#786dc1' : 'none')};
  z-index: 4;
`;
