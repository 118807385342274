import { useState } from "react";
import { useSnackbar } from "notistack";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import useReponseErr from "../reponseErr";
import { setDashboardData } from "store/actions/project";

function useSelectedType() {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const { responseErr } = useReponseErr();
    const tokenVal = useSelector((state) => state.project.bodyToken);
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();

    function getDashBoardData(values) {
        setLoading(true);
        Axios.post("/systemdashboard", {
            ...values,
            ...tokenVal,
        })
            .then((res) => {
                dispatch(setDashboardData(res.data.data))
                setData(res.data);
                setSuccess(true);
                // enqueueSnackbar("Migrate Successful", {
                //     variant: "success",
                // });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                responseErr(err);
                setSuccess(false);
            });
    }

    // if (!loading) {
    //     closeSnackbar("key");
    // } else {
    //     enqueueSnackbar("Kindly wait while we migrate your project", {
    //         persist: true,
    //         variant: "default",
    //         key: "key",
    //     });
    // }

    return {
        loading,
        getDashBoardData,
        data,
        success
    };
}

export default useSelectedType;
