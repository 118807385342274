import React, { useState, useEffect } from "react";
import localForage from "localforage";
import { ReactComponent as CloneIcon } from "assets/Icons/copy.svg";
import useClone from "helpers/custom-hooks/clone";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "components/Spinner/Spinner";
import { cloneSuccess } from "store/actions/project";
import { SwapOutlined } from "@ant-design/icons";
import useMigrate from "helpers/custom-hooks/migrate";

function MigrateProject({ migrateData, setPage }) {
    const { migrate, isMigrating, migrateSuccess } = useMigrate();
    const [, setVisibility] = useState(true);
    const currentProject = useSelector((state) => state.project.currentProject);
    const cloneData = useSelector((state) => state.project.cloneData);
    const dispatch = useDispatch();

    function handleDragOver(e) {
        e.preventDefault();
    }

    function handleDropZone(e) {
        e.preventDefault();
        localForage.getItem("single_project_credentials").then((res) => {
            migrate({
                ...res,
                project_key: currentProject.key,
                dist: migrateData,
            });
        });
    }

    function closeAlert() {
        dispatch(cloneSuccess(""));
        setVisibility(false);
    }

    function handleSubmission() {
        localForage.getItem("single_project_credentials").then((res) => {
            migrate({
                ...res,
                project_key: currentProject.key,
                dist: migrateData,
            });
        });
    }

    useEffect(() => {
        if (migrateSuccess) {
            setPage(4);
        }
    }, [migrateSuccess]);

    return (
        <div>
            <div className="operation-body">
                <div className="project-details" draggable={!isMigrating}>
                    <div className="project-name-id">
                        <h3 className="grey-primary-text">Project Name</h3>
                        <span className="num">
                            {currentProject && currentProject.name}
                        </span>
                    </div>
                    <div className="">
                        <h3 className="grey-primary-text">No of IssueTypes</h3>
                        <span className="num">
                            {currentProject && currentProject.NoOfIssueTypes}
                        </span>
                    </div>
                    <div className="holder">
                        <div className="">
                            <h3 className="grey-primary-text">No of Issues</h3>
                            <span className="num">
                                {currentProject && currentProject.NoOfIssues}
                            </span>
                        </div>
                        <CloneIcon className="clone-icon" />
                    </div>
                </div>
                <SwapOutlined className="swap-icon" />
                <div
                    className="project-details"
                    onDragOver={handleDragOver}
                    onDrop={handleDropZone}
                >
                    <div className="project-name-id">
                        <h3 className="grey-primary-text">Domain Name</h3>
                        <span className="num small">
                            {migrateData && migrateData.domain}
                        </span>
                    </div>
                    <div className="">
                        <h3 className="grey-primary-text">Email</h3>
                        <span className="num small">
                            {migrateData && migrateData.email}
                        </span>
                    </div>
                    <div className="holder">
                        <div className="">
                            <h3 className="grey-primary-text">API key</h3>
                            <span className="num">* * * * * * * * * * *</span>
                        </div>
                    </div>
                </div>
                <div className="btn-holder">
                    {isMigrating ? (
                        <Spinner />
                    ) : (
                        <button
                            className="operation-btn"
                            onClick={handleSubmission}
                        >
                            Start Migrating
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MigrateProject;
