/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Switch, Route, useLocation } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import 'antd/dist/antd.css';
import { useDispatch } from 'react-redux';
// import Page, { Grid, GridColumn } from '@atlaskit/page';
import './App.css';
import Dashboard from 'pages/Dashboard/index';
import LandingPage from 'pages/Landing Page/index';
import VisualizeCommunity from 'pages/Visualize Community/index';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import localforage from 'localforage';
import useProjects from 'helpers/custom-hooks/projects';
import { modalToggleState, jwtTokenRes } from 'store/actions/project';
import { getProjectFromStorage } from 'helpers/utils';
import queryString from 'query-string';
import ErrorBoundary from 'helpers/errorBoundary';
import StorageError from 'helpers/storageError';

library.add(faSpinner, faTimes);

localforage.config({
  name: 'myApp',
  version: 1.0,
  storeName: 'single_project',
  description: 'single_project',
});

const theme = {
  colors: {
    primary_dark_blue: '#1D1554',
    secondary_light_blue: '#2B11E1',
    secondary_dark_blue: '#374DD0',
  },
};

const GlobalStyle = createGlobalStyle`
body{
  background:#1C1454;
  background:  ${(props) => (props.mode ? '#1C1454' : '#fff')};
  height:100vh;
}
`;

const App = () => {
  const { projectSessionInit, loading } = useProjects();
  const dispatch = useDispatch();
  const project = getProjectFromStorage();
  const location = useLocation();

  const [toggle, setToggle] = useState(false);

  function handleToggle() {
    setToggle((prevState) => !prevState);
  }


  useEffect(() => {
    projectSessionInit();

    const query = location.search;
    const values = queryString.parse(query);

    if (values.jwt) {
      dispatch(modalToggleState(false));
      dispatch(jwtTokenRes({ token: values.jwt }));
    } else if (Object.keys(project).length) {
      dispatch(modalToggleState(false));
      dispatch(jwtTokenRes({ token: values.jwt }));
    } else {
      dispatch(modalToggleState(true));
    }
  }, []);

  useEffect(() => {
    try {
      if (window.localStorage) {
      }
    } catch (e) {
      // Access denied :-(
      console.error('=========================================================');
      console.error('=========================================================');
      console.error("========Can't access LocalStorage, Kindly Enable!========");
      console.error('=========================================================');
      console.error('=========================================================');
      return <StorageError />;
    }
  }, [window.localStorage]);

  return (
    <ErrorBoundary>
      <SnackbarProvider maxSnack={1} preventDuplicate anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <GlobalStyle mode={toggle} />
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path="/community" exact component={ VisualizeCommunity } />
            <Route path="/home" exact component={LandingPage}/>
            <Route path="/" render={() => <Dashboard projectsLoading={loading} toggle={toggle} handleToggle={handleToggle} />} component={Dashboard}/>
          </Switch>
        </ThemeProvider>
      </SnackbarProvider>
    </ErrorBoundary>
  );
};

export default App;
