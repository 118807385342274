/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import { OperationStyled, SelectProjectText } from "pages/Clone/cone.style";
import ChartOne from "./chart1";
import ChartTwo from "./chartTwo";
import { useTransition, animated } from "react-spring";
import useWindowDimensions from "helpers/custom-hooks/windowDimension";
import useVisualize from "helpers/custom-hooks/visualize";
import localForage from "localforage";
import { useSelector } from "react-redux";
import { Spinner } from "components/Spinner/Spinner";
import { Select } from "antd";
import ChartPie from "./chartPie";
import ChartBarLine from "./chartPie";

const { Option } = Select;

function Visualize({ mode, expandedView, handleExpandedView }) {
    const [chartNum, setChartNum] = useState(1);
    const { width } = useWindowDimensions();
    const [chartType, setChartType] = useState("bar");
    const [{ chartHeight, chartWidth }, setDimension] = useState({});
    const { visualize, data, loading } = useVisualize();
    const currentProject = useSelector((state) => state.project.currentProject);
    const project = JSON.parse(localStorage.getItem("single_project_main"));

    useEffect(() => {
        try {
            localForage.getItem("single_project_credentials").then((res) => {
                visualize({ ...res, project_key: project && project.key });
            });
        } catch (err) {
            console.log(err);
        }
    }, [currentProject]);

    function handlePrev() {
        if (chartNum === 1) {
            return;
        }
        setChartNum(chartNum - 1);
    }

    function handleNext() {
        if (chartNum === chartOneData.length) {
            return;
        }
        setChartNum(() => chartNum + 1);
    }

    function handleChartPosition(e) {
        setChartNum(parseInt(e.currentTarget.dataset.position));
    }

    useEffect(() => {
        try {
            let chartWidth = "";
            let chartHeight = "";
            if (expandedView) {
                chartWidth = "1000";
                chartHeight = "350";
            } else if (!expandedView && width > 1199) {
                chartWidth = "750";
                chartHeight = "250";
            } else if (!expandedView) {
                chartWidth = "650";
                chartHeight = "350";
            }

            setDimension({
                chartWidth,
                chartHeight,
            });
        } catch (err) {
            console.log(err);
        }
    }, [expandedView, chartHeight, chartWidth, width]); 

    // const chartOneData =
    //     daa &&
    //     Object.entries(daa).map((dt) => {
    //         console.log("dtdtdrtdt", dt)
    //         return Object.entries(dt[1]).map((d) => {
    //             return {
    //                 title: dt[0],
    //                 name: d[0],
    //                 value: d[1],
    //             };
    //         });
    //     });


        const chartOneData = Object.entries(data && data.data["Status statusCategory"]).map(el => {
              return {
                  title: el[0],
                  name: el[0],
                  value: el[1],
              };
        })
	

    function handleChart() {
        let chart = [];

        chart = (
            <ChartBarLine
                type=""
                width={chartWidth}
                height={chartHeight}
                data={chartOneData && chartOneData}
                text={"Status statusCategory"}
            />
        );

        // switch (chartNum) {
        //     // case 1:
        //     //     chart = (
        //     //         <ChartBarLine
        //     //             type={chartType}
        //     //             mode={mode}
        //     //             width={chartWidth}
        //     //             height={chartHeight}
        //     //             data={
        //     //                 data && chartOneData && chartOneData[chartNum - 1]
        //     //             }
        //     //             text={data && chartOneData[0].find((d) => d).title}
        //     //         />
        //     //     );
        //     //     break;
        //     case 1:
        //         chart = (
        //             <ChartBarLine
        //                 type=""
        //                 width={chartWidth}
        //                 height={chartHeight}
        //                 data={chartOneData && chartOneData}
        //                 text={"Status statusCategory"}
        //             />
        //         );
        //         break;
        //     // case 3:
        //     //     chart = (
        //     //         <ChartBarLine
        //     //             type={"bar"}
        //     //             width={chartWidth}
        //     //             height={chartHeight}
        //     //             data={data && chartOneData[chartNum - 1]}
        //     //             text={data && chartOneData[2].find((d) => d).title}
        //     //         />
        //     //     );
        //     //     break;
        //     // case 4:
        //     //     chart = (
        //     //         <ChartBarLine
        //     //             type={chartType}
        //     //             width={chartWidth}
        //     //             height={chartHeight}
        //     //             data={data && chartOneData[chartNum - 1]}
        //     //             text={data && chartOneData[3].find((d) => d).title}
        //     //         />
        //     //     );
        //     //     break;
        //     // default:
        //     //     chart = (
        //     //         <ChartBarLine
        //     //             type={chartType}
        //     //             mode={mode}
        //     //             width={chartWidth}
        //     //             height={chartHeight}
        //     //             data={data && chartOneData[chartNum - 1]}
        //     //             text={data && chartOneData[0].find((d) => d).title}
        //     //         />
        //     //     );
        //     //     break;
        // }
        return currentProject && Object.keys(currentProject).length === 0 ? (
            <SelectProjectText>Select a project to Visualize</SelectProjectText>
        ) : (
            chart
        );
    }

    const transitions = useTransition(chartNum, null, {
        config: { duration: 300 },
        from: { opacity: 0, transform: "translate3d(100%,0,0)" },
        enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
        leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
    });

    function handleChange(value) {
        setChartType(value);
    }

    console.log(currentProject)
    console.log(project)
    console.log(data)


    return (
        <OperationStyled mode={mode} className="expanded-view">
            <div className="operation-content">
                <div className="operation-header">
                    <span className="title">Visualize</span>
                    {/* <div className="">
                        <Select onChange={handleChart} placeholder="select chart" style={{width: "150px"}}>
                            <Option value="bar">Bar</Option>
                            <Option value="line">Line</Option>
                            <Option value="pie">Bar</Option>
                        </Select>
                    </div> */}
                    <div className="other-info">
                        <div className="project-id">
                            <span className="project-icon" />
                            <span>{currentProject && currentProject.name}</span>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    {loading ? (
                        <Spinner />
                    ) : (
                        transitions.map(({ item, props, key }) => {
                            return (
                                <animated.div style={props} key={key}>
                                    <div className="visualise-data-container">
                                        <div className="visualize-data">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Status</th>
                                                        <th>Count</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data &&
                                                        data.counter_data &&
                                                        Object.entries(
                                                            data.counter_data
                                                        )
                                                            .sort(
                                                                (a, b) =>
                                                                    b[1] - a[1]
                                                            )
                                                            .map(
                                                                (el, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>
                                                                                {
                                                                                    el[0]
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    el[1]
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="chart">
                                            {data && handleChart()}
                                        </div>
                                    </div>
                                </animated.div>
                            );
                        })
                    )}
                </div>
                <div className="chart-operations">
                    {/* <div className="handle-chart-position">
            <button onClick={handlePrev}>prev</button>
            <button onClick={handleNext}>next</button>
          </div> */}
                    <div className="dots">
                        {/* {data &&
              chartOneData.map((d, index) => {
                return <span role="button" onClick={handleChartPosition} data-position={index + 1} className={`dot ${chartNum === index + 1 && ' empty-dot'}`} />;
              })} */}
                    </div>
                    {/* <button className="expand" onClick={handleExpandedView}>
            {expandedView ? 'collapse view' : 'expand view'}
          </button> */}
                </div>
            </div>
        </OperationStyled>
    );
}

export default Visualize;
