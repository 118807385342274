import React, { useState, useEffect } from "react";
import localForage from "localforage";
import {
    SelectedProjectStyled,
    ProjectAvailabityText,
} from "./selected-project.style";
import { useDispatch, useSelector } from "react-redux";
import useProjects from "helpers/custom-hooks/projects";
import { Spinner } from "components/Spinner/Spinner";
import useWindowDimensions from "helpers/custom-hooks/windowDimension";
import {
    setCurrentFilter, setDashboardCurrentScreen,
} from "store/actions/project";
import useDeepCompareEffect from "use-deep-compare-effect";

const SelectedFilter = ({ collapsible, handleCollapsible, loading, mode }) => {
    const current = useSelector((state) => state.project.currentFilter) || "";
    const dashboardData =
        useSelector((state) => state.project.dashboardData) || "";
    const [searchedFilters, setSearchFilters] = useState(dashboardData.filters);
    const [searchText, setSearchText] = useState("");
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();

    async function handleChecked(e) {
        const foundfilter = dashboardData.filters.find(
            (filter) => filter.id === e.target.value
        );
        dispatch(setCurrentFilter(foundfilter));
        dispatch(setDashboardCurrentScreen({ name: "filters" }));
    }

    function handleRadioReponsiveness(project) {
        let projectName = "";
        if (project.name.length > 15 && width <= 1199) {
            projectName = project.name.slice(0, 20) + "...";
        } else if (project.name.length > 15 && width > 1199) {
            projectName = projectName = project.name.slice(0, 10) + "...";
        } else if (project.name) {
            projectName = project.name;
        }

        return <span>{projectName}</span>;
    }

    function handleChange(e) {
        setSearchText(e.target.value);
    }

    useDeepCompareEffect(() => {
        const results =
            dashboardData &&
            dashboardData.filters &&
            dashboardData.filters.filter((filter) =>
                filter.name.toLowerCase().includes(searchText.toLowerCase())
            );
        setSearchFilters(results);
    }, [dashboardData , searchText]);

    return (
        <>
            {/* {loading && (
                <div className="spinner-holder">
                    <div className="spinner-content">
                        <Spinner />
                        <div className="spinner-text">
                            fetching filters information
                        </div>
                    </div>
                </div>
            )} */}
            <div className="project-title">
                <span className="title">Selected Filter</span>
                <span className="close-holder"></span>
            </div>
            <div className="current-project">
                <div className="current-project-icon"></div>
                <div className="project-description">
                    <span className="project-name">
                        {(current && current.name) || "Filter name"}
                    </span>
                    <span className="project-id">
                        {(current && current.id) || "Filter ID"}
                    </span>
                </div>
            </div>
            <div className="projects">
                <span className="available-project-title">
                    Available Filters
                </span>
                <input
                    type="text"
                    onChange={handleChange}
                    placeholder="Search by Filter Name"
                    className="search-input"
                />
                <div className="radio-holder">
                    {loading ? (
                        <ProjectAvailabityText>
                            <Spinner />
                        </ProjectAvailabityText>
                    ) : dashboardData &&
                      dashboardData.filters &&
                      dashboardData.filters.length <= 0 ? (
                        <ProjectAvailabityText>
                            You have no release at the moment
                        </ProjectAvailabityText>
                    ) : (
                        dashboardData &&
                        dashboardData.filters &&
                        searchedFilters.map((filter) => {
                            return (
                                <label
                                    key={filter.id}
                                    className={`radio-container ${
                                        current.id === filter.id &&
                                        "active-radio"
                                    }`}
                                    title={filter.name}
                                >
                                    <input
                                        type="radio"
                                        name="name"
                                        className={
                                            current.id === filter.id &&
                                            " active-radio"
                                        }
                                        value={filter.id}
                                        onChange={handleChecked}
                                        checked={current.id === filter.id}
                                    />
                                    <span
                                        className="radio-text"
                                        title={filter.name}
                                    >
                                        {handleRadioReponsiveness(filter)}
                                    </span>
                                    <span class="checkmark" />
                                </label>
                            );
                        })
                    )}
                </div>
            </div>
            <div className="caret" onClick={handleCollapsible} role="button">
                {collapsible ? <span>&#8744;</span> : <span>&#8743;</span>}
            </div>
        </>
    );
};

export default SelectedFilter;
