import React from 'react';
import './report.css';


const Report = ({report,idx,handleClick}) =>{
    const { topic,author,votes,comments,status,image_urls,id }=report;
    // const id = reportNumber;
    return(
        <div className={`report ${idx}`} key={idx} onClick={ ()=>handleClick(id) }>
            <div className="report-details">
                <div className="report-img">
                    <img src={image_urls[0] === "" ? require("../../assets/images/test.jpg"): image_urls[0] } alt="report img"/>
                </div>
                <div className="report-info">
                    <h3 className="report-title">{topic}</h3>
                    <h4 className="report-owner">by {author}</h4>
                </div>
            </div>
            <div className="report-votes-comments">
                <div className="report-votes">
                    <img className="votes-icon" src={require("../../assets/Icons/thumbs-up.svg")} alt="votes icon"/>
                    <h4 className="votes-count">{votes}</h4>
                </div>
                <div className="report-comments">
                    <img src={ require('../../assets/Icons/message-circle.svg') } className="comments-icon" alt="comment icon"/>
                    <h4 className="comments-count">{comments.length}</h4>
                </div>
            </div>
            <p className={`report-status ${status}`}>{status}</p>
        </div>
    )
}

export default Report;