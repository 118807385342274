import styled from "styled-components";

export const MiniCardStyle = styled.div`
    height: 120px;
    width: 180px;
    border-radius: 4px;
    display: grid;
    justify-items: center;
    place-content: center;
    color: #3e3e3e;
    font-weight: 300;
    font-size: 17px;
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: transform 400ms ease-in-out;
    position: relative;
    overflow: hidden;

    .coming-soon{
        font-size:13px;
        position: absolute;
        bottom: 10px;
        display: block;

    }
     &.selected-type {
        transform: scale(1.1);
        transition: transform 500ms ease-in-out;
    }

    .check-icon {
        position: absolute;
        right: 12px;
        bottom: 10px;
        color: inherit;
        font-size: 22px;
        opacity: 0;
        transition: opacity 300ms ease-in-out;
        fill: #fff;

        &.selected-check-icon {
            opacity: 1;
        }
    }

    .icon-ab {
        position: absolute;
        left: -40px;
        top: -50px;
        opacity: 0.09;
        font-size: 110px;
        transition: transform 500ms ease-in-out;
    }

    .l-icon {
        font-size: 40px;
        color: #3e3e3e;
        height: auto;
    }

    &:hover {
        transform: scale(1.1);
    }

    &:hover .icon-ab {
        transform: scale(1.3);
    }
`;
