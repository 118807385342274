import styled from 'styled-components';

export const RadioGroup = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
`;

export const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .icon {
    width: 50px;
    height: 50px;
  }
`;

export const ErrorText = styled.div`
  font-size: 14px;
  color: #000;
  margin-top: 20px;
  text-align: center;
`;
