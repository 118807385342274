import React from "react";
import { useSelector } from "react-redux";

export default function CloneSuccess() {
    const cloneData = useSelector((state) => state.project.cloneData);

    const data = Object.entries(cloneData && cloneData["compare_data"]).map(
        (el) => {
            return el.map((element) => {
                return {
                    name: el[0],
                    source: element.source,
                    clone: element.clone,
                };
            });
        }
    );

    return (
        <div>
            <div className="clone-data-container">
                <div className="visualize-data full-width-data">
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Source</th>
                                <th>Clone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data &&
                                data.sort((a, b) => b[1].source - a[1].source).map((el) => {
                                    return (
                                        <tr>
                                            <td>{el[1].name}</td>
                                            <td>{el[1].clone}</td>
                                            <td>{el[1].source}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
