import styled from 'styled-components';

const HeaderStyled = styled.div`
  position: fixed;
  height: 10vh;
  width: 100vw;
  top: 0;
  padding-left: 90px;
  padding-right: 120px;
  background-color: #fff;
  color: #2a10e1;
  grid-area: header;
  z-index: 2;
  display: flex;
  justify-content: space-between;

  .header {
    background-color: #fff;
    color: #2a10e1;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    margin-left: 30px;
    justify-content: space-between;

    .title {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 1.3px;
    }

    .other-options {
      display: flex;

      .option {
        margin-left: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .plus-icon,
        .bell-icon {
          width: 28px;
          height: 28px;
        }

        &-text {
          margin-left: 10px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: -0.1px;
        }

        .notifcation-count {
          height: 22px;
          width: 22px;
          background: #06edc2;
          color: #2a10e1;
          border-radius: 50%;
          position: absolute;
          top: 10px;
          left: -10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
        }
      }
    }
  }
`;

export { HeaderStyled };
