import OnBoardInput from 'components/input/onBoarding';
import PageHeader from 'components/Title';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { MigrateStyle } from './migrate.style';
import {FormAnimationNext} from "../../helpers/animation"

export default function EnterCredentials({ setPage: setMigratePage, setMigrateData }) {
    const schema = {
        1: Yup.object().shape({
            domain: Yup.string()
                .min(2, 'Too Short!')
                .max(128, 'Too Long!')
                .required('domain cannot be left blank'),
        }),
        2: Yup.object().shape({
            email: Yup.string()
                .email('invalid email')
                .required('email cannot be left blank'),
        }),
        3: Yup.object().shape({
            api_key: Yup.string()
                .min(2, 'Too Short!')
                .max(256, 'Too Long!')
                .required('api key cannot be left blank')
                .nullable(),
        }),
    };

    const [page, setPage] = useState(1);
    const [fields, setFields] = useState({
        domain: {
            value: '',
            isValid: false,
            isChanged: false,
            error: null,
        },
        email: {
            value: '',
            isValid: false,
            isChanged: false,
            error: null,
        },
        api_key: {
            value: '',
            isValid: false,
            isChanged: false,
            error: null,
        },
    });

      const isIdle = () => {
          switch (page) {
              case 1:
                  return !fields.domain.isValid;
              case 2:
                  return !fields.email.isValid;
              case 3:
                  return !fields.api_key.isValid;
              default:
                  return false;
          }
      };

    const handlePrevious = () => {
        if(page === 1) return;
            setPage(page - 1);
    }  

    const setPageHandler = () => {
        if(page >= 3){
            setMigrateData({
                api_key: fields.api_key.value,
                domain: fields.domain.value,
                email: fields.email.value,
            });
            setMigratePage(3);
        }
        setPage(page + 1);
    };

    const handleChange = ({ target }) => {
        const fieldData = { ...fields };
        fieldData[target.name]['value'] = target.value;
        fieldData[target.name]['isChanged'] = Boolean(target.value);
        try {
            fieldData[target.name]['isValid'] = Boolean(
                schema[page].validateSync({ [target.name]: target.value }),
            );
            schema[page].validateSync({ [target.name]: target.value });
            fieldData[target.name]['error'] = '';
        } catch (e) {
            fieldData[target.name]['isValid'] = false;
            fieldData[target.name]['error'] = e.message;
        }
        setFields(fieldData);
    };


    return (
        <MigrateStyle>
            <form>
                <div className="migrate-credential">
                    {
                        {
                            1: (
                                <FormAnimationNext key={page}>
                                    <PageHeader title="What is your domain ?" />
                                    <OnBoardInput
                                        config={{
                                            placeholder:
                                                'Please enter your domain here',
                                            name: 'domain',
                                            onChange: handleChange,
                                            value: fields.domain.value,
                                        }}
                                        error={fields.domain.error}
                                        isChanged={fields.domain.isChanged}
                                        isValid={fields.domain.isValid}
                                    />
                                </FormAnimationNext>
                            ),
                            2: (
                                <FormAnimationNext key={page}>
                                    <PageHeader title="Provide email address" />
                                    <OnBoardInput
                                        config={{
                                            placeholder:
                                                'Please enter your email here',
                                            name: 'email',
                                            onChange: handleChange,
                                            value: fields.email.value,
                                        }}
                                        error={fields.email.error}
                                        isChanged={fields.email.isChanged}
                                        isValid={fields.email.isValid}
                                    />
                                </FormAnimationNext>
                            ),
                            3: (
                                <FormAnimationNext key={page}>
                                    <PageHeader title="API Key" />
                                    <OnBoardInput
                                        config={{
                                            placeholder:
                                                'Please enter your API key',
                                            name: 'api_key',
                                            type: 'password',
                                            onChange: handleChange,
                                            value: fields.api_key.value,
                                        }}
                                        error={fields.api_key.error}
                                        isChanged={fields.api_key.isChanged}
                                        isValid={fields.api_key.isValid}
                                    />
                                </FormAnimationNext>
                            ),
                        }[page]
                    }
                </div>
                <div className="btn-holder">
                    <button
                        type="button"
                        disabled={page === 1}
                        className="btn-white"
                        onClick={() => handlePrevious()}>
                        Back
                    </button>

                    <button
                        type="button"
                        className="operation-btn"
                        disabled={isIdle()}
                        onClick={() => setPageHandler()}>
                        Next
                    </button>
                </div>
            </form>
        </MigrateStyle>
    );
}
