import React from 'react';
import { motion } from 'framer-motion';

export const FormAnimationPrevious = ({ children, key }) => {
    return (
        <motion.div
            key={key}
            initial={{ opacity: 0, y: 0, x: 40 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 40 }}>
            {children}
        </motion.div>
    );
};

export const FormAnimationNext = ({ children, key }) => {
    return (
        <motion.div
            key={key}
            initial={{ opacity: 0, y: 0, x: -40 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -40 }}>
            {children}
        </motion.div>
    );
};
