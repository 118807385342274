import styled from 'styled-components';

export const RadioWrapper = styled.div`
  padding: 10px;
  border-radius: ${(props) => props.radius || '5px'};
  color: #1d1554;
  width: 45%;
  height: 60px;
  margin: 5px 0;
  border: ${(props) => (props.isOnBoarding ? '0.5px solid #1D1554' : '1px solid #2a10e194')};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  min-width: 100px;
  transition: all 100ms ease-in-out;

  &:nth-child(1),
  &:nth-child(2) {
    margin-top: 0.4rem !important;
  }

  &:last-child {
    margin-bottom: 0.4rem;
  }
  & + & {
    margin-top: 1rem;
  }
  position: relative;
  background: ${(props) => {
    return props.checked ? '#374dd0' : '#fff';
  }};
  &:hover {
    background: #374dd0;
    color: #fff;
    border: none;

    .project-des {
      .project-key {
        font-size: 8px;
        color: #fff;
      }
    }
  }
  &.active-radio {
    background: #2910e1;
    color: #fff;
  }
  .input-container {
    position: relative;
    margin-right: 10px;
    input {
      opacity: 0;
      cursor: pointer;
      pointer-events: none;
    }
    .checkmark-container {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 0.8px solid #1d1554;
      pointer-events: none;
      top: 0;
      right: 0;
    }
    .checkmark {
      pointer-events: none;
      background-color: #0aedc2;
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 50%;
    }
  }
  .project-des {
    pointer-events: none;

    .project-name {
      display: block;
      font-weight: 500;
      pointer-events: none;
      text-transform: capitalize !important;
    }

    .project-key {
      pointer-events: none;
      font-size: 8px;
      color: #606060;
      display: block;
    }
  }
`;
