import React,{ useState } from 'react';
import Axios from 'axios';
import './ReportForm.css';

const ReportForm = ({ setReportForm, reportForm, token }) =>{
    const [ reportTitle, setReportTitle ] = useState('');
    const [ reportDescription, setReportDescription ] = useState('');
    const [ attachmentLink, setAttachmentLink ] = useState('');
    const [ disableSubmit, setDisableSubmit ] = useState(true);

    // const [newReport, setNewReport] = useState({title:"", description:"",imageUrls:""});

    const handleSubmit = (e) =>{
        e.preventDefault()
        const newReport = ({
            token:token,
            topic:reportTitle ,
            description:reportDescription,
            image_urls:[attachmentLink]
        })

        Axios.post("https://api.scrumcompanion.net/api/v1/reports", newReport )
        .then(
            res=>(console.log(res.data))
        )
        .catch(
            e=>(console.log(e))
        )

        resetForm();
        setReportForm(false);
        console.log(newReport);
    }

    const resetForm = () =>{
        setAttachmentLink('');
        setReportTitle('');
        setReportDescription('');
        setDisableSubmit(true);
        document.getElementById("report-form").reset();
        
    }

    const uploadImages = async e =>{
        const files = e.target.files;
        const attachmentData = new FormData();
        attachmentData.append('file',files[0]);
        attachmentData.append('upload_preset',"attachment");
        attachmentData.append('api_key',"845428747732564");

        Axios.post("https://api.cloudinary.com/v1_1/geniesys/image/upload", attachmentData )
        .then( 
            res=>{
                if(res.data.secure_url){
                    setAttachmentLink(res.data.secure_url);
                    setDisableSubmit(false);
                }else{
                    setDisableSubmit(true);
                }
                
            } 
        );
    }


    return(
        <div className={`report-form ${reportForm===true ? "show-form":""}`}>
            <div className="close-btn" onClick={ ()=>setReportForm(false) }>
                <div className="bar bar-1"></div>
                <div className="bar bar-2"></div>
            </div>
            <div className="main-form">
                <h3 className="form-title">Add Report</h3>
                <form id="report-form" onSubmit={ handleSubmit }>
                    <div className="form-input">
                        <label htmlFor="report-title">Report Title</label>
                        <input 
                            name="report-title" 
                            type="text"
                            value={reportTitle}
                            required 
                            className="report-title-input" 
                            onChange={ (e)=>setReportTitle(e.target.value) }

                            />
                    </div>
                    <div className="form-input">
                        <label htmlFor="report-description">Report Description</label>
                        <textarea 
                            name="report-description" 
                            type="text" 
                            value={ reportDescription }
                            required 
                            className="report-description-input"
                            onChange={ (e)=>setReportDescription(e.target.value) }
                        />
                    </div>
                    <div className="form-input">
                        <label htmlFor="report-attachment">Report Attachment</label>
                        <input 
                            name="report-attachment" 
                            type="file" 
                            // value={ e=> handleSubmit ? "" : e.target.value }
                            required 
                            className="report-attachment-input"
                            onChange={ uploadImages }
                        />
                    </div>
                    <input type="submit" className={`submit-btn ${disableSubmit ? "disable": "" }`} value="Submit Report"/>
                </form>
            </div>

            
            <div className="mask"></div>
        </div>
    )
}

export default ReportForm;