import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import projectReducer from './store/actions/reducers';
import axios from 'axios';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';

// Base Url
// axios.defaults.baseURL = 'https://api.scrumcompanion.net/api/v1';
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/v1' : `https://api.scrumcompanion.net/api/v1`;
// axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/v1' : `https://${process.env.REACT_APP_API_URL}/api/v1`;
// axios.interceptors.request.use(function (config) {
//   const token =
//   config.headers.Authorization = token;

//   return config;
// });
const rootReducer = combineReducers({
  project: projectReducer,
});
// https://stackoverflow.com/questions/57628279/composeenhancers-is-not-a-function-in-reactjs
const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
