import React from 'react';

export default function InitilizeMigrate({setPage}) {
    return (
        <div className="operation-body">
            <div className="operation-box" onClick={() => setPage(2)}>
                <div className="add-icon" />
                <span className="grey-secondary-text mb-3">Add Instance</span>
            </div>

            <div className="btn-holder">
                <button className="operation-btn" onClick={() => setPage(2)}>
                    Proceed
                </button>
            </div>
        </div>
    );
}
