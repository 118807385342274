import Axios from "axios";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import localForage from "localforage";
import { projectsLoading, saveProjects, setCurrentProject } from "store/actions/project";
import { useHistory, useLocation } from "react-router-dom";
import { saveProjectToStorage, getProjectFromStorage } from "helpers/utils";
import queryString from "query-string";

function useProjects() {
    const [loading, setloading] = useState(false);
    const [projectLoading, setProjectLoading] = useState(false);
    const [error, setError] = useState(false);
    const projects = useSelector((state) => state.project.projects);
    const project = getProjectFromStorage();
    const dispatch = useDispatch();
    const history = useHistory();
    const STORE_NAME = "single_project";
    const location = useLocation();

    async function getProjects(values) {
        setloading(true);
        dispatch(projectsLoading(true));

        try {
            const result = await Axios.post(`/projects`, values);
            if (result) {
                await localForage.setItem("single_project_credentials", values);
                dispatch(saveProjects(result.data.data));
                dispatch(projectsLoading(false));
                setloading(false);
            }
        } catch (error) {
            dispatch(projectsLoading(false));
            setloading(false);
            setError(true);
        }
    }

    async function getProject(key, values) {
        setProjectLoading(true);
        try {
            const result = await Axios.post(`/projects/${key}`, values);
            if (result) {
                setProjectLoading(false);
                localStorage.setItem(
                    "single_project_main",
                    JSON.stringify(result.data.data)
                );
                localForage.setItem(STORE_NAME, result.data.data).then(() => {
                    dispatch(setCurrentProject(result.data.data));
                });
                saveProjectToStorage(values);
            }
        } catch (error) {
            console.log("error", error);
            setProjectLoading(false);
            setError(true);
        }
    }

    async function projectSessionInit() {
        const result = await localForage.getItem("single_project_credentials");
        const query = location.search;
        const values = queryString.parse(query);

        if (values.jwt) {
            return getProjects({
                token: values.jwt,
            });
        } else {
            if (Object.keys(project).length <= 0) {
                return history.push("/");
            } else {
                getProjects(result);
                localForage
                    .getItem(STORE_NAME)
                    .then((data) => {
                        dispatch(setCurrentProject(data));
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }

    return {
        getProjects,
        getProject,
        loading,
        projects,
        error,
        projectSessionInit,
        projectLoading,
        project,
    };
}

export default useProjects;
