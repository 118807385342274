import React, { useState,useEffect } from 'react';
import { ReactComponent as VisualizeIcon } from 'assets/Icons/visualize.svg';
import { ReactComponent as ExtractIcon } from 'assets/Icons/extract.svg';
import { ReactComponent as CloneIcon } from 'assets/Icons/clone.svg';
import { ReactComponent as MigrateIcon } from 'assets/Icons/migrate.svg';
import { MainHeaderStyled, NavLinkMainStyled } from './main-header.style';
import { Link } from 'react-router-dom';
import { setDashboardCurrentScreen } from 'store/actions/project';
import { useDispatch,useSelector } from 'react-redux';

const MainContent = ({ mode }) => {
    const dispatch = useDispatch();
    const [ migrateDestination,setMigrateDestination ] = useState("");
    const [ cloneDestination,setCloneDestination ] = useState("");
    const currentDashboardScreen = useSelector((state)=>state.project.type);
    useEffect(()=>{
        if(currentDashboardScreen === "releases" || currentDashboardScreen === "filters"){
            setMigrateDestination("/")
        }
        else{
            setMigrateDestination("/migrate")
        }
        
        
    }, [currentDashboardScreen])

    useEffect(()=>{
        if(currentDashboardScreen === "releases" || currentDashboardScreen === "filters"){
            setCloneDestination("/")
        }else{
            setCloneDestination("/clone")
        }
        
        
    }, [currentDashboardScreen])
    
  return (
      <MainHeaderStyled mode={mode}>
          <div className="action-header">
              <span className="action-title">Actions</span>
              <span className="action-close">
                  <Link to="/"  onClick={() => dispatch(setDashboardCurrentScreen({name: "root"}))}>
                      {" "}
                      Go Home
                  </Link>
              </span>
          </div>
          <div className="links-holder">
              <NavLinkMainStyled
                  mode={mode}
                  activeClassName="extract-active"
                  to={`/extract`}
                  exact
              >
                  <span className="nav-text">Extract</span>
                  <span className="icon-holder">
                      <ExtractIcon className="icon extract-icon" />
                  </span>
              </NavLinkMainStyled>
              <NavLinkMainStyled
                  mode={mode}
                  activeClassName="visualize-active"
                  to={`/visualize`}
                  exact
              >
                  <span className="nav-text">Visualize</span>
                  <span className="icon-holder">
                      <VisualizeIcon className="icon visualize-icon" />
                  </span>
              </NavLinkMainStyled>
              <NavLinkMainStyled
                  mode={mode}
                  className={`${currentDashboardScreen === "releases" || currentDashboardScreen === "filters" ? "disable" : ""}`}
                  activeClassName={`${currentDashboardScreen === "releases" || currentDashboardScreen === "filters" ? "" :"clone-active" }`}
                  to={ cloneDestination }
                  exact
              >
                  <span className="nav-text">Clone</span>
                  <span className="icon-holder">
                      <CloneIcon className="icon clone-icon" />
                  </span>
              </NavLinkMainStyled>
              <NavLinkMainStyled
                  mode={mode}
                  className={`${currentDashboardScreen === "releases" || currentDashboardScreen === "filters" ? "disable" : ""}`}
                  activeClassName={`${currentDashboardScreen === "releases" || currentDashboardScreen === "filters" ? "" :"migrate-active" }`}

                to={ migrateDestination }
              >
                  <div className="nav-text">
                      {/* <span className="nav">Migrate</span>
            <span className="text">coming soon</span> */}
                      <span className="nav-text">Migrate</span>
                  </div>
                  <span className="icon-holder">
                      <MigrateIcon className="icon migrate-icon" />
                  </span>
              </NavLinkMainStyled>
          </div>
      </MainHeaderStyled>
  );
};

export default MainContent;
