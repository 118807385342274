import { useState } from 'react';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import useReponseErr from '../reponseErr';
import { useSelector } from 'react-redux';

function useVisualize() {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { responseErr } = useReponseErr();
  const [data, setData] = useState('');
  const [visualizeReleaseData, setVisualizeReleaseData] = useState("");
  const token = useSelector((state) => state.project.bodyToken);

  function visualize(values) {
    setLoading(true);
    Axios.post('/visualize', { ...values, ...token })
      .then((res) => {
        setData(res.data);
        // saveVisualizeData(res.data.data);
        enqueueSnackbar('Successful', { variant: 'success' });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        responseErr(err);
      });
  }

  function getVisualizeReleaseData(values){
     setLoading(true);
     Axios.post("/visualize/release", { ...values, ...token })
         .then((res) => {
             setVisualizeReleaseData(res.data);
             enqueueSnackbar("Successful", { variant: "success" });
             setLoading(false);
         })
         .catch((err) => {
             setLoading(false);
             responseErr(err);
         });
  }

  if (!loading) {
    closeSnackbar('key');
  } else {
    enqueueSnackbar('Getting visualization Data', { persist: true, variant: 'default', key: 'key' });
  }

  return {
      loading,
      data,
      visualize,
      visualizeReleaseData,
      getVisualizeReleaseData,
  };
}

export default useVisualize;
