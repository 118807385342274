import { FormAnimationNext, FormAnimationPrevious } from "helpers/animation";
import useSelectedType from "helpers/custom-hooks/selectedType";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import SelectedFilter from "./selected-filters";
import SelectedProject from "./selected-project";
import { SelectedProjectStyled } from "./selected-project.style";
import SelectedRelease from "./selected-release";
import localForage from "localforage";


export default function SideBarViewType({
    collapsible,
    handleCollapsible,
    loading: projectsLoading,
    mode,
}) {
    const type = useSelector((state) => state.project.type);
    const { getDashBoardData, loading } = useSelectedType();

    useEffect(() => {
        localForage.getItem("single_project_credentials").then((res) => {
            getDashBoardData({ ...res });
        });
    }, []);

    function handleSelectedTypeView() {
        let content;
        switch (type) {
            case "projects":
                content = (
                    <FormAnimationNext key={type}>
                        <SelectedProject
                            mode={mode}
                            collapsible={collapsible}
                            handleCollapsible={handleCollapsible}
                        />
                    </FormAnimationNext>
                );
                break;
            case "releases":
                content = (
                    <FormAnimationPrevious key={type}>
                        <SelectedRelease
                            mode={mode}
                            collapsible={collapsible}
                            handleCollapsible={handleCollapsible}
                            loading={loading}
                        />
                    </FormAnimationPrevious>
                );
                break;
            case "filters":
                content = (
                    <FormAnimationPrevious key={type}>
                        <SelectedFilter
                            mode={mode}
                            collapsible={collapsible}
                            handleCollapsible={handleCollapsible}
                            loading={loading}
                        />
                    </FormAnimationPrevious>
                );
                break;
            default:
                break;
        }

        return content;
    }

    return (
        <SelectedProjectStyled
            mode={mode}
            className={`projects ${collapsible && " collapsible"}`}
        >
            {handleSelectedTypeView()}
        </SelectedProjectStyled>
    );
}
