import styled from 'styled-components';

export const MigrateStyle = styled.div`
    width: 100%;

    .migrate-credential {
        border: 1px dashed #2c11e1;
        border-radius: 10px;
        width: 70%;
        height: 250px;
        margin: 0 auto;
    }

    .btn-holder {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .operation-btn {
            &:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }

        .btn-white {
            cursor: pointer;
            border: 1px solid #06edc2;
            border-radius: 20px;
            padding: 1.4px 15px;
            transition: background 300ms ease;
            font-size: 14px;
            color: ${(props) => (props.mode ? '#2a10e1' : '#2a10e1')};
            background-color: #fff;
            &:focus {
                outline: 0;
            }

            &:hover {
                background: #06edc2;
            }

            &:nth-child(2) {
                margin-left: 10px;
            }

            &:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }
    }
`;
