import {
    DoubleRightOutlined,
    FilterFilled,
    ProjectFilled,
    PullRequestOutlined,
} from "@ant-design/icons";
import ReleaseVisualize from "components/DashboardScreens/ReleaseVisualize";
import FiltersVisualize from "components/DashboardScreens/FiltersVisualize";
import { OperationStyled } from "pages/Clone/cone.style";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import MiniCard from "../../components/MiniCard/index";

export default function Landing() {
    const [type, setType] = useState("");

    const currentDashboardScreen = useSelector(
        (state) => state.project.currentDashboardScreen
    );

    useState(()=>{
        console.log(currentDashboardScreen.name)
    },[])

    const cards = [
        {
            id: 1,
            name: "projects",
            color: "#f04f45",
            icon: ProjectFilled,
        },
        {
            id: 2,
            name: "releases",
            color: "#06edc2",
            icon: PullRequestOutlined,
        },
        {
            id: 1,
            name: "sprint",
            color: "#ff7b38",
            icon: DoubleRightOutlined,
            future: true
        },
        {
            id: 3,
            name: "filters",
            color: "#fdd800",
            icon: FilterFilled,
        },
    ];


    let content;
    switch (currentDashboardScreen?.name) {
        case "root":
            content = (
                <div className="mini-cards">
                    {cards.map((card) => (
                        <MiniCard
                            key={card.name}
                            card={card}
                            currentCard={type}
                            handleClick={() => setType(card.name)}
                        />
                    ))}
                </div>
            );
            break;
        case "release":
            content = <ReleaseVisualize />;
            break;

        case "filters":
            content = <FiltersVisualize />;
            break;
        default:
                content = (
                    <div className="mini-cards">
                        {cards.map((card) => (
                            <MiniCard
                                key={card.name}
                                card={card}
                                currentCard={type}
                                handleClick={() => setType(card.name)}
                            />
                        ))}
                    </div>
                );
            break;
    }

    return (
        <OperationStyled>
            <div className="heading">Home</div>
            <div className="operation-body">
                {content}
            </div>
        </OperationStyled>
    );
}
