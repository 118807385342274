import { CheckCircleFilled } from "@ant-design/icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { sideBarType } from "store/actions/project";
import { MiniCardStyle } from "./minicard.style";

export default function MiniCard({ card, handleClick, currentCard }) {
    const type = useSelector((state) => state.project.type);
    const dispatch = useDispatch();

    const { icon: Icon } = card;

    function handleCardClick() {
        if (card.name === "sprint"){
            return;
        } 
        
        dispatch(sideBarType(card.name));
        handleClick();
    }

    return (
        <MiniCardStyle
            style={{ background: card.color }}
            onClick={handleCardClick}
            className={` ${currentCard === card.name ? "selected-type" : ""}`}
            
        >
            <span className="icon-ab">
                <Icon />
            </span>
            <CheckCircleFilled
                className={`check-icon ${
                    type === card.name ? "selected-check-icon" : ""
                } `}
            />
            {card.future && <span class="coming-soon">Coming Soon</span>}
            <span>
                <Icon className="l-icon" />
            </span>
            {card.name}
        </MiniCardStyle>
    );
}
