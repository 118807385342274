import { useSnackbar } from 'notistack';

function useReponseErr() {
  const { enqueueSnackbar } = useSnackbar();

  function responseErr(err) {
    let message = '';
    const stat = err.response && err.response.status ? err.response.status : 500;
    switch (Number(stat)) {
      case 404:
        message = 'There is no such project with the key provided';
        break;
      case 401:
        message = 'Your token is Invalid';
        break;
      case 400:
        message = err.response.message ? err.response.message : 'your request required a token and a project key';
        break;
      default:
        message = 'Some error occurred, Please try again';
        break;
    }
    enqueueSnackbar(message, { variant: 'error' });
  }

  return {
    responseErr,
  };
}

export default useReponseErr;
