import styled from 'styled-components';

const RightSideBarStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 90px;
  background-color: #1c1454;
  color: #fff;
  z-index: 2;
  grid-area: rightsidebar;
  border-left: 1px solid ${(props) => (props.mode ? '#786dc1' : 'none')};

  display: flex;
  justify-content: center;
  padding: 15px 0;

  .profile-icon {
    position: absolute;
  }

  .other-options {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
    z-index: 1;

    .docs,
    .support {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        outline: 0;
      }

      &:focus {
        outline: 0;
      }

      .support-icon {
        transform: rotate(270deg);
      }

      span {
        margin-right: 20px;
      }
    }
  }
`;

export { RightSideBarStyled };
