import React from 'react';
import { LeftSideBarStyled, NavLinkStyled } from './left-sidebar.style';
import { ReactComponent as VisualizeIcon } from 'assets/Icons/visualize-color.svg';
import { ReactComponent as ExtractIcon } from 'assets/Icons/extract-color.svg';
import { ReactComponent as CloneIcon } from 'assets/Icons/clone-color.svg';
import { ReactComponent as MigrateIcon } from 'assets/Icons/migrate-color.svg';
import { ReactComponent as Logo } from 'assets/Logo/logo-main.svg';
import Switch from 'react-switch';

function LeftSideBar({ onToggle, mode }) {
  return (
      <LeftSideBarStyled mode={mode}>
          <div className="sidebar-content">
              <ul className="sidebar-items">
                  <div className="brand-name">
                      <Logo className="logo" />
                      <span className="logo-text">scrum companion</span>
                  </div>
                  <li className="sidebar-item">
                      <NavLinkStyled activeClassName="is-active" to={`/extract`} exact>
                          <ExtractIcon className="extract" />
                          <div className="text-holder">
                              <span className="text">Extract</span>
                          </div>
                      </NavLinkStyled>
                  </li>
                  <li className="sidebar-item">
                      <NavLinkStyled
                          activeClassName="is-active"
                          exact
                          to={'/visualize'}>
                          <VisualizeIcon className="visualize" />
                          <div className="text-holder">
                              <span className="text">Visualize</span>
                          </div>
                      </NavLinkStyled>
                  </li>
                  <li className="sidebar-item">
                      <NavLinkStyled
                          activeClassName="is-active"
                          exact
                          to={'/clone'}>
                          <CloneIcon className="clone" />
                          <div className="text-holder">
                              <span className="text">Clone</span>
                          </div>
                      </NavLinkStyled>
                  </li>

                  <li className="sidebar-item">
                      <NavLinkStyled activeClassName="is-active" to={'/migrate'}>
                          <MigrateIcon className="migrate" />
                          <div className="text-holder">
                              <span className="text">Migrate</span>
                          </div>
                      </NavLinkStyled>
                  </li>
              </ul>
              {/* <div className="dark-mode-switch">
                  <span>Dark Mode</span>
                  <Switch
                      checked={Boolean(mode)}
                      onChange={onToggle}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor="#3332A0"
                  />
              </div> */}
          </div>
      </LeftSideBarStyled>
  );
}

export default LeftSideBar;
